import { REQUEST_STATUSES } from '../../../constants/requestStatuses';

const initialState = {
    mediaItems: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
    noResults: false,
};

export const mediaItemsSelector = (propertyId) => (state) => ({
    ...initialState,
    ...(state.mediaItems[propertyId] || {}),
});
