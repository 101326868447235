import { REQUEST_STATUSES } from '../../../constants/requestStatuses';

const initialState = {
    elements: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const elementsSelector = (propertyId, experienceId) => (state) => {
    const elements = state.elements[`${propertyId}${experienceId}`] || {};

    return { ...initialState, ...elements };
};
