import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { MobilePhoneIcon, HelpIcon, MailIcon } from '../../../../../../../icons';
import { NOTIFICATION_OPTIONS } from '../../../../constants';

const styles = () => ({
    formControlLabelText: {
        display: 'inline-block',
        marginRight: '9px',
    },
});

function LabelText(props) {
    const { classes, email, option } = props;

    const NOTIFICATION_OPTION_LABELS = {
        [NOTIFICATION_OPTIONS.SMS]: 'Send SMS Texts to my phone',
        [NOTIFICATION_OPTIONS.EMAIL]: email ? `Email me at ${email}` : 'Email me',
        [NOTIFICATION_OPTIONS.PUSH]: 'Send Push Notifications',
        DEFAULT: 'Enable Notifications',
    };

    switch (option) {
        case NOTIFICATION_OPTIONS.SMS:
            return (
                <>
                    <span className={classes.formControlLabelText}>{NOTIFICATION_OPTION_LABELS[option]}</span>
                    <MobilePhoneIcon htmlColor="currentColor" style={{ fontSize: '16px' }} />
                </>
            );

        case NOTIFICATION_OPTIONS.EMAIL:
            return (
                <>
                    <span className={classes.formControlLabelText}>{NOTIFICATION_OPTION_LABELS[option]}</span>
                    <MailIcon htmlColor="currentColor" style={{ fontSize: '10px' }} />
                </>
            );

        case NOTIFICATION_OPTIONS.PUSH:
            return (
                <>
                    <span className={classes.formControlLabelText}>{NOTIFICATION_OPTION_LABELS[option]}</span>
                    <HelpIcon htmlColor="currentColor" style={{ fontSize: '16px' }} />
                </>
            );

        default:
            return <span className={classes.formControlLabelText}>{NOTIFICATION_OPTION_LABELS.DEFAULT}</span>;
    }
}

export default withStyles(styles)(LabelText);
