import { createSlice } from '@reduxjs/toolkit';

const check = (state, index) => {
    if (!state[index]) {
        state[index] = {};
    }
};

export const VisualBuilderDataSlice = createSlice({
    name: 'visualBuilderData',
    initialState: {},
    reducers: {
        initIndex: (state, { payload }) => {
            const { mediaLibId, primaryFilter } = payload;
            const previousInitialization = state[mediaLibId] || {};
            state[mediaLibId] = {
                rightDrawer: true,
                leftDrawer: true,
                carousel: true,
                leftDrawerAutoHide: true,
                rightDrawerAutoHide: true,
                ...previousInitialization,
                primaryFilter: primaryFilter || 'type',
                elementData: {},
                hotspotMode: { status: false, type: null },
                mapHotspotCanvas: {
                    open: false,
                    elementId: null,
                    url: null,
                },
                popoverAction: 'link',
                activeHotspot: null,
                mapPreview: null,
                hotspotReorders: 0,
                fromHotspot: false,
            };
        },
        setHotspotMode: (state, { payload }) => {
            const { indexId, hotspotMode } = payload;
            check(state, indexId);
            state[indexId].hotspotMode = hotspotMode;
        },
        setPopoverAction: (state, { payload }) => {
            const { indexId, popoverAction } = payload;
            check(state, indexId);
            state[indexId].popoverAction = popoverAction;
        },
        setHoveredHotspot: (state, { payload }) => {
            const { indexId, hoveredHotspot } = payload;
            check(state, indexId);
            state[indexId].hoveredHotspot = hoveredHotspot;
        },
        setActiveHotspot: (state, { payload }) => {
            const { indexId, activeHotspot } = payload;
            check(state, indexId);
            state[indexId].activeHotspot = activeHotspot;
        },
        setHotspotReorders: (state, { payload }) => {
            const { indexId, hotspotReorders } = payload;
            check(state, indexId);
            state[indexId].hotspotReorders += hotspotReorders;
        },
        setFromHotspot: (state, { payload }) => {
            const { indexId, fromHotspot } = payload;
            check(state, indexId);
            state[indexId].fromHotspot = fromHotspot;
        },
        setMapPreview: (state, { payload }) => {
            const { indexId, mapPreview } = payload;
            check(state, indexId);
            state[indexId].mapPreview = mapPreview;
        },
        setMapHotspotCanvas: (state, { payload }) => {
            const { indexId, mapHotspotCanvas } = payload;
            check(state, indexId);
            state[indexId].mapHotspotCanvas = mapHotspotCanvas;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    initIndex,
    setHotspotMode,
    setMapHotspotCanvas,
    setPopoverAction,
    setHoveredHotspot,
    setActiveHotspot,
    setHotspotReorders,
    setFromHotspot,
    setMapPreview,
} = VisualBuilderDataSlice.actions;

export default VisualBuilderDataSlice.reducer;

export const visualBuilderDataSliceSelector = (mediaLibId) => (state) => state.visualBuilderData[mediaLibId] || {};
