import React from 'react';

const EntireExperienceMobile = (props) => {
    return (
        <svg width="67" height="121" viewBox="0 0 67 121" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_d_3196_67607)">
                <rect
                    x="5.25568"
                    y="4.5"
                    width="56.5"
                    height="112"
                    rx="4.5"
                    stroke="white"
                    shapeRendering="crispEdges"
                />
            </g>
            <g clipPath="url(#clip0_3196_67607)">
                <path
                    d="M82.117 47.0917C120.043 25.7672 147.533 39.496 153.081 43.0677C153.599 43.4016 153.881 43.9835 153.881 44.6005V110.887C153.881 111.992 152.985 112.887 151.881 112.887H-9.11932C-10.2239 112.887 -11.1193 111.992 -11.1193 110.887V83.5512C-11.1193 81.9288 -9.24699 80.9364 -7.76546 81.5975C11.3741 90.1384 44.0523 68.4941 82.117 47.0917Z"
                    fill="#595959"
                />
                <path
                    d="M47.8294 70.8803C10.7377 52.4223 -5.56421 49.9546 -10.1636 51.1795C-10.7994 51.3488 -11.1193 51.9781 -11.1193 52.636V110.887C-11.1193 111.992 -10.2239 112.887 -9.11933 112.887H151.881C152.985 112.887 153.881 111.992 153.881 110.887V64.8436C153.881 63.2723 152.159 62.2951 150.793 63.0714C130.517 74.5935 86.137 89.9432 47.8294 70.8803Z"
                    fill="#888888"
                />
            </g>
            <line x1="5.88068" y1="15.5" x2="61.8807" y2="15.5" stroke="white" />
            <rect x="5.25568" y="97.5" width="56.5" height="12.8279" fill="#C90451" stroke="white" />
            <circle cx="21.9732" cy="104.162" r="2.52273" fill="white" />
            <rect x="26.6583" y="101.639" width="22.3442" height="5.04545" rx="2" fill="white" />
            <defs>
                <filter
                    id="filter0_d_3196_67607"
                    x="0.755676"
                    y="0"
                    width="65.5"
                    height="121"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3196_67607" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3196_67607" result="shape" />
                </filter>
                <clipPath id="clip0_3196_67607">
                    <rect width="55" height="82" fill="white" transform="translate(5.88068 17)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EntireExperienceMobile;
