import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FolderIcon = (props) => {
    return (
        <SvgIcon width="38px" height="26px" viewBox="0 0 38 26" {...props}>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                <g id="Icon/Folder/Red" transform="translate(-21.000000, -12.000000)" stroke="#8F0339">
                    <path d="" id="Path-4" />
                    <polyline
                        id="Path-5"
                        points="22.0473258 36.8294408 22.0473258 12.8294408 34.8256033 12.8294408 34.8256033 15.8707501 54.0473258 15.8707501 54.0473258 18.9441616 54.0473258 18.9441616"
                    />
                    <polygon
                        id="Rectangle"
                        points="25.2000696 18.8294408 58.0473258 18.8294408 54.8945819 36.8294408 22.0473258 36.8294408"
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

export default FolderIcon;
