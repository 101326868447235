import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowLeftPrimary = (props) => {
    return (
        <SvgIcon fontSize="small" width={13} height={29} viewBox="0 0 13 29" {...props}>
            <defs>
                <rect id="path-1" x="0" y="104" width="354" height="946" />
                <filter
                    x="-16.9%"
                    y="-6.3%"
                    width="133.9%"
                    height="112.7%"
                    filterUnits="objectBoundingBox"
                    id="filter-2"
                >
                    <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                    />
                </filter>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="PM-Content-Type-Sorting-Copy" transform="translate(-37.000000, -205.000000)">
                    <rect
                        id="Rectangle"
                        stroke={props.htmlColor ? props.htmlColor : '#8F0339'}
                        x="0"
                        y="169"
                        width="354"
                        height="100"
                    />
                    <polyline
                        id="Path-17-Copy-2"
                        stroke={props.htmlColor ? props.htmlColor : '#8F0339'}
                        points="49 205 38 219.5 49 234"
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

const ArrowLeftSecondary = (props) => {
    const { color = '#333333' } = props;
    return (
        <SvgIcon width="8" height="14" viewBox="0 0 8 14" fill="none" {...props}>
            <path d="M7 1L0.999999 7L7 13" stroke={color} strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </SvgIcon>
    );
};

const ArrowLeft = ({ type = 'primary', ...props }) => {
    const Component = {
        primary: ArrowLeftPrimary,
        secondary: ArrowLeftSecondary,
    }[type];
    return <Component {...props} />;
};

export default ArrowLeft;
