export const univVideosSelector = (state) => {
    const { videos } = state.univVideos;
    const categoryVideos = {};

    videos.forEach((video) => {
        if (categoryVideos.hasOwnProperty(video.category)) {
            categoryVideos[video.category].push(video);
        } else {
            categoryVideos[video.category] = [];
            categoryVideos[video.category].push(video);
        }
    });

    return { ...state.univVideos, categoryVideos };
};
