import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CloseSmall = (props) => {
    return (
        <SvgIcon width="15px" height="13px" viewBox="0 0 15 13" {...props}>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Close/White" transform="translate(0.000000, -1.000000)" stroke={props.htmlColor || '#fff'}>
                    <line x1="14" y1="14" x2="1" y2="1" id="Line-2" />
                    <line x1="14" y1="1" x2="1" y2="14" id="Line-2" />
                </g>
            </g>
        </SvgIcon>
    );
};

export default CloseSmall;
