import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    contactExperiences: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const contactExperiencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CONTACT_EXPERIENCES: {
            const { contactExperiences, status, error, query } = action.payload;
            const newContactExperiences = getNewList(query, state.contactExperiences, contactExperiences);
            const indexes = getListIndexes(newContactExperiences);
            return {
                ...state,
                contactExperiences: newContactExperiences,
                status,
                error,
                indexes,
            };
        }
        case types.REFRESH_CONTACT_EXPERIENCES: {
            const { changes } = action.payload;
            const newContactExperiences = handleListRefresh(state.contactExperiences, state.indexes, changes);

            return { ...state, contactExperiences: newContactExperiences };
        }
        case types.SET_CONTACT_EXPERIENCES_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_CONTACT_EXPERIENCES:
            return { ...initialState };
        default:
            return state;
    }
};
