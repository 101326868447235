import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    mediaLib: {},
    tagIndexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const currentMediaLibReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CURRENT_MEDIA_LIB: {
            const { mediaLib } = action.payload;
            const { tags = [] } = mediaLib;
            const tagIndexes = tags.reduce((accumulator, value, index) => {
                return { ...accumulator, [value]: index };
            }, {});

            return {
                ...state,
                ...action.payload,
                tagIndexes,
                changed: new Date().getTime(),
            };
        }
        case types.REFRESH_CURRENT_MEDIA_LIB: {
            const { mediaLib } = action.payload;
            const { tags = [] } = mediaLib;
            const tagIndexes = tags.reduce((accumulator, value, index) => {
                return { ...accumulator, [value]: index };
            }, {});

            return {
                ...state,
                ...action.payload,
                tagIndexes,
                changed: new Date().getTime(),
            };
        }
        case types.SET_CURRENT_MEDIA_LIB_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_CURRENT_MEDIA_LIB:
            return { ...initialState, changed: new Date().getTime() };
        default:
            return state;
    }
};
