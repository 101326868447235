import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { TAB_STATUSES } from '../constants/tabStatuses';
import { currentUserSelector } from '../store/v2/currentUser/selectors';

export const parseParams = () => {
    const paths = window.location.pathname.split('/').filter((item) => !!item);
    const [root, uid, page, uid2, tabStatus] = paths;
    const newParams = { root };
    newParams.page = page;

    if (root === 'properties' && uid) {
        newParams.propertyId = uid;

        if (!page) {
            newParams.tabStatus = TAB_STATUSES.HOMEBASE;
        } else if (tabStatus) {
            newParams.tabStatus = tabStatus;
        } else if (page && uid2) {
            newParams.tabStatus = TAB_STATUSES.PUBLIC;
        }
    }

    if (page) {
        newParams.page = page;
        if (page === 'experiences' && uid2) {
            newParams.experienceId = uid2;
        }
    }

    return newParams;
};

export const useUrlParams = () => {
    const {
        user: { id: userId },
    } = useSelector(currentUserSelector);

    const [params, setParams] = useState({ ...parseParams(), userId });

    useEffect(() => {
        setParams({ ...parseParams(), userId });
    }, [userId, window.location.pathname]);

    return params;
};
