import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowRightIcon = (props) => {
    return (
        <SvgIcon width="10px" height="20px" viewBox="0 0 10 20" {...props}>
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g
                    id="Media-Library_add-content-modal-hover-state"
                    transform="translate(-1041.000000, -515.000000)"
                    stroke={props.htmlColor || '#8F0339'}
                >
                    <polyline
                        id="Path-6-Copy-2"
                        transform="translate(1046.000000, 525.000000) scale(-1, 1) translate(-1046.000000, -525.000000) "
                        points="1050 515 1042 525 1050 535"
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

export default ArrowRightIcon;
