import React from 'react';

const CheckMarkCircle = ({ htmlColor = '#ffffff' }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.71423 9.69457L11.1382 14.5711L18.1428 3.14258"
                stroke={htmlColor || '#6DD400'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.7432 8.75692C20.9111 9.47826 21 10.2292 21 11C21 16.5237 16.5259 21 11.0049 21C5.48395 21 1 16.5237 1 11C1 5.47628 5.47407 1 10.9951 1C12.0222 1 13.0198 1.1581 13.958 1.44466"
                stroke={htmlColor || '#6DD400'}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default CheckMarkCircle;
