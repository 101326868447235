import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ProfileRedIcon = (props) => {
    return (
        <SvgIcon width="16px" height="19px" viewBox="0 0 16 19" {...props}>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                <g id="Icon/Profile/Red" transform="translate(-7.000000, -5.000000)" stroke="#8F0339">
                    <path
                        d="M22,18.7710407 L22,22.8 L8,22.8 L8,18.7710407 C8,16.4144796 9.58064516,14.4228054 11.7784946,13.8146606 C10.8903226,12.9769412 10.3935484,11.8229864 10.3935484,10.561086 C10.3935484,8.05248869 12.455914,6 15.0015054,6 C16.2795699,6 17.4236559,6.51540271 18.2516129,7.33639819 C19.0946237,8.15891403 19.6064516,9.31438914 19.6064516,10.561086 C19.6064516,11.8229864 19.1096774,12.9769412 18.2365591,13.8146606 C19.1849462,14.0883258 20.027957,14.6204525 20.6752688,15.3182986 C21.5032258,16.2320362 22,17.4483258 22,18.7710407 Z"
                        id="Stroke-1"
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

export default ProfileRedIcon;
