import * as types from './types';

const query = (query, action) => {
    const { payload } = action;

    switch (action.type) {
        case types.ON_ADD_FILTER_QUERY:
        case types.ON_EDIT_FILTER_QUERY:
            return { ...query, ...payload };

        default:
            return query;
    }
};

const queries = (queries = [], action) => {
    const { index } = action;

    switch (action.type) {
        case types.ON_ADD_FILTER_QUERY:
            return [...queries, query({}, action)];

        case types.ON_EDIT_FILTER_QUERY:
            return queries.map((q, i) => {
                if (index !== i) {
                    return q;
                }
                return query(q, action);
            });

        case types.ON_REMOVE_FILTER_QUERY:
            return queries.filter((item, i) => i !== index);

        default:
            return queries;
    }
};

const search = (search = {}, action) => {
    const { payload } = action;

    if (action.type === types.ON_SET_SEARCH) {
        return { ...search, value: payload };
    }

    return search;
};

const filter = (filter = {}, action) => {
    const { payload } = action;

    switch (action.type) {
        case types.ON_FILTER_OPEN_CHANGED:
        case types.ON_FILTER_SORT_FIELD_CHANGED:
            return { ...filter, ...payload };
        case types.ON_FILTER_SORT_ORIENTATION_CHANGED:
            return { ...filter, ...payload };
        case types.ON_FILTER_FIELD_NAME_CHANGED:
            return { ...filter, ...payload };
        case types.ON_FILTER_FIELD_VALUE_CHANGED:
            return { ...filter, ...payload };
        case types.ON_FILTER_SEARCH_TEXT_CHANGED:
            return { ...filter, ...payload };

        case types.ON_ADD_FILTER_QUERY:
        case types.ON_EDIT_FILTER_QUERY:
        case types.ON_REMOVE_FILTER_QUERY:
            return { ...filter, queries: queries(filter.queries, action) };

        case types.ON_SET_SEARCH:
            return { ...filter, search: search(filter.search, action) };

        default:
            return filter;
    }
};

const filters = (state = {}, action) => {
    const { name } = action;
    switch (action.type) {
        case types.ON_FILTER_OPEN_CHANGED:
        case types.ON_FILTER_SORT_FIELD_CHANGED:
            return { ...state, [name]: filter(state[name], action) };
        case types.ON_FILTER_SORT_ORIENTATION_CHANGED:
            return { ...state, [name]: filter(state[name], action) };
        case types.ON_FILTER_FIELD_NAME_CHANGED:
            return { ...state, [name]: filter(state[name], action) };
        case types.ON_FILTER_FIELD_VALUE_CHANGED:
            return { ...state, [name]: filter(state[name], action) };
        case types.ON_FILTER_SEARCH_TEXT_CHANGED:
            return { ...state, [name]: filter(state[name], action) };
        case types.ON_ADD_FILTER_QUERY:
        case types.ON_EDIT_FILTER_QUERY:
        case types.ON_REMOVE_FILTER_QUERY:
        case types.ON_SET_SEARCH:
            return { ...state, [name]: filter(state[name], action) };

        default:
            return state;
    }
};

export default filters;
