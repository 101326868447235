import { getDatabase, ref, onValue, off } from 'firebase/database';
import * as types from './types';
import { logError } from '../errors/actions';

export function onConnectionStateChange(isConnected) {
    return {
        type: types.ON_CONNECTION_STATE_CHANGED,
        payload: { isConnected },
    };
}

export function initConnection(firebaseApp, onChange) {
    const db = getDatabase(firebaseApp);

    return (dispatch) => {
        const docRef = ref(db, '.info/connected');
        onValue(
            docRef,
            (snapshot) => {
                dispatch(onConnectionStateChange(snapshot.val()));

                if (onChange !== undefined && onChange instanceof Function) {
                    onChange(snapshot.val());
                }
            },
            (err) => {
                console.error(err);
                dispatch(logError('.info/connected', err));
            },
        );
    };
}

export function unsubscribeConnection(firebaseApp) {
    const db = getDatabase(firebaseApp);

    return (dispatch) => {
        const docRef = ref(db, '.info/connected');
        off(docRef);
        dispatch(onConnectionStateChange(false));
    };
}
