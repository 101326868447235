import { defineMessages } from 'react-intl';

const messages = defineMessages({
    app_name: 'React Najtrazeniji',
    dashboard: 'Pregled',
    about: 'Opis',
    page_not_found_demo: 'Stranica nije pronadjena',
    404: '404',
    warning_404_message: '404 stranica nije pronadjena',
    warning_404_description: 'Zao nam je ali trazena stranica nepostoji',
    settings: 'Postavke',
    language: 'Jezik',
    theme: 'Tema',
    responsive: 'Responsive',
    en: 'English',
    de: 'Deutsch',
    bs: 'Bosanski',
    ru: 'Русский',
    es: 'Español',
    dark: 'Tamna',
    light: 'Svijetla',
    ics: 'ICS',
    sign_out: 'Odjaviti',
    sign_in: 'Prijaviti',
    sign_up: 'Registrovati',
    sign_in_with_google: 'Sa Google prijaviti',
    sign_in_with_facebook: 'Sa Facebookom prijaviti',
    sign_in_with_twitter: 'Sa Twitterom prijaviti',
    sign_in_with_github: 'Sa Githubom prijaviti',
    'link_with_google.com': 'Sa Google povezati',
    'link_with_facebook.com': 'Sa Facebookom povezati',
    'link_with_twitter.com': 'Sa Twitterom povezati',
    'link_with_github.com': 'Sa Githubom povezati',
    my_account: 'Moj profil',
    name: 'Ime',
    email: 'E-Mail',
    password: 'Sifra',
    new_password: 'Nowa Sifra',
    confirm_password: 'Potvrdi sifru',
    forgort_password: 'Zaboravio sifru?',
    reset_password: 'Resetuj sifru',
    change_password: 'Promjeni sifru',
    change_email: 'Promjeni E-Mail',
    change_photo: 'Promjeni Sliku',
    reset_password_hint: 'Unesi svoj E-Mail',
    save: 'Spasi',
    delete_account: 'Izbrisi profil',
    select_file: 'Izaberi datoteku',
    cancel: 'Odustani',
    submit: 'Potvrdi',
    delete: 'Izbrisi',
    ok: 'OK',
    delete_account_dialog_title: 'Izbrisati profil?',
    delete_account_dialog_message: 'Tvoj profil i svi njegovi podaci ce biti izbrisani!',
    email_not_verified: 'E-Mail nije verificiran!',
    email_verified: 'E-Mail je verificiran',
    send_verification_email: 'Posalji E-Mail za verifikaciju',
    send_verification_email_again: 'Posalji ponove E-Mail za verifikaciju',
    tasks: 'Zadaci',
    create_task: 'Dodaj zadatak',
    edit_task: 'Uredi zadatak',
    users: 'Korisnici',
    edit: 'Izmjeniti',
    online: 'Online',
    offline: 'Offline',
    no_connection_warning: 'Nema konekcije!',
    title_label: 'Naslov',
    title_hint: 'Unesi naslov',
    no_connection: 'Nema konekcije',
    delete_task_title: 'Izbrisati zadatak?',
    delete_task_message: 'Zadatak ce bit izbrisan!',
    error: 'Greska!',
    companies: 'Preduzece',
    create_company: 'Dodaj preduzece',
    edit_company: 'Uredi preduzece',
    delete_company_title: 'Izbrici preduzece?',
    delete_company_message: 'Preduzece ce biti izbrisano!',
    full_name_label: 'Puno ime',
    full_name_hint: 'Unesi puno ime',
    vat_label: 'UID',
    vat_hint: 'Unesi UID',
    description_label: 'Opis',
    description_hint: 'Unesi opis',
    name_label: 'Ime',
    name_hint: 'Puno ime',
    public_chats: 'Javni chat',
    delete_message_title: 'Izbrisi poruku?',
    delete_message_message: 'Poruka ce biti izbrisana!',
    users_count_title: '{number} korisnika',
    user_registrationg_graph_label: 'Registracije korisnika',
    required: 'Potrebno',
    facebook: 'Facebook',
    github: 'Github',
    twitter: 'Twitter',
    phone: 'Phone',
    google: 'Google',
    facebook_color: '#303F9F',
    github_color: '#263238',
    twitter_color: '#36A2EB',
    phone_color: '#90A4AE',
    google_color: '#EA4335',
    password_color: '#4CAF50',
    chats: 'Chats',
    write_message_hint: 'Napisi poruku...',
    load_more_label: 'Vise...',
    my_location: 'Mojoj lokacija',
    select_user: 'Izaberi korisnika',
    operator_like_label: 'like',
    operator_notlike_label: 'not like',
    operator_equal_label: 'equal',
    operator_notequal_label: 'not equal',
    operator_novalue_label: 'no value',
    administration: 'Administration',
    roles: 'Uloge',
    grants: 'Prava',
    private: 'Privatni',
    public: 'Javni',
    grant_read_companies: 'Citati firme',
    grant_create_company: 'Dodati firmu',
    grant_edit_company: 'Urediti firmu',
    grant_delete_company: 'Obrisati firmu',
    is_admin_label: 'Administrator',
    predefined_messages: 'Predefined messages',
    delete_predefined_chat_message_title: 'Delete predefined message?',
    delete_predefined_chat_message_message: 'Predefined message will be deleted!',
    select_field: 'Select field',
    sorting: 'Sorting',
    filters: 'Filter',
    filter: 'Filter',
    add_filter: 'Add filter',
    delete_filter: 'Delete filter',
    change_sort_orientation: 'Change orientation',
    enable_case_sensitivity: 'Case sensitivity',
    hint_autocomplete: 'Select',
    enter_query_text: 'Enter text',
    email_label: 'Email',
    close_filter: 'Close filter',
    open_filter: 'Open filter',
    select_operator: 'Select operator',
    not_match_found: 'No match found',
    edit_user: 'Edit user',
    firestore: 'Firestore',
    hot_dog_status: 'Hot dog status',
    user_label_search: 'Search user',
    document: 'Document',
    collection: 'Collection',
    infinitelist: 'Infinitelist',
    activate: 'aktivirati',
    deactivate: 'deaktivirati',
});

export default messages;
