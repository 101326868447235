import * as types from './types';

const locale = (state = 'en', action) => {
    if (action.type === types.UPDATE_LOCALE) {
        return action.locale;
    }

    return state;
};

export default locale;
