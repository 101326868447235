import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DesktopIcon = (props) => {
    const { htmlColor } = props;
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <rect x="4" y="6" width="16" height="9.6" stroke={htmlColor || '#000'} strokeLinejoin="round" fill="none" />
            <path
                d="M5.12269 13.8064H18.7728"
                stroke={htmlColor || '#000'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M7.55518 18H16.4441" stroke={htmlColor || '#000'} strokeLinecap="round" />
        </SvgIcon>
    );
};

export default DesktopIcon;
