import * as types from './types';

const defaultState = {
    editDialog: { open: false, mediaItemId: null },
    elementLinkDialog: {
        open: false,
    },
    createLink: false,
};

export const simpleValuesReducer = (state = defaultState, action) => {
    if (action.type === types.ON_SIMPLE_VALUE_CHANGED) {
        return { ...state, [action.id]: action.value };
    }

    return state;
};
