import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowUpIcons = (props) => {
    return (
        <SvgIcon width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12 5.72998L6.57627 0.899628L1.15254 5.72998"
                stroke={props.htmlColor || '#71B6BC'}
                strokeLinecap="round"
                fill="none"
            />
        </SvgIcon>
    );
};

export default ArrowUpIcons;
