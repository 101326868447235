import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SingleProperty = (props) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3996 8.22589C17.3996 9.21804 17.1139 10.1446 16.618 10.9365L12.0003 18.4286L7.39462 10.9553L7.29939 10.7998C6.85356 10.0406 6.59961 9.16222 6.59961 8.22589C6.59961 5.33804 9.01709 3 12.0003 3C14.9828 3 17.3996 5.33804 17.3996 8.22589Z"
                fill="none"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7997 8.39998C13.7997 9.39378 12.9941 10.2 12.0001 10.2C11.0061 10.2 10.1997 9.39378 10.1997 8.39998C10.1997 7.40617 11.0061 6.59998 12.0001 6.59998C12.9941 6.59998 13.7997 7.40617 13.7997 8.39998Z"
                fill="none"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
            />
            <path
                d="M15.9026 15.7416C17.8869 16.2503 19.1998 17.1359 19.1998 18.143C19.1998 19.7209 15.9762 21 12.0001 21C8.02344 21 4.7998 19.7209 4.7998 18.143C4.7998 17.0343 6.38978 16.074 8.7138 15.6"
                fill="none"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="2 3"
            />
        </SvgIcon>
    );
};

export default SingleProperty;
