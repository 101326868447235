import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    icons: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const iconsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ICONS: {
            const { icons, status, error, query } = action.payload;
            const newIcons = getNewList(query, state.icons, icons);
            const indexes = getListIndexes(newIcons);
            return {
                ...state,
                icons: newIcons,
                status,
                error,
                indexes,
                noResults: !newIcons.length,
            };
        }
        case types.REFRESH_ICONS: {
            const { changes } = action.payload;
            const newIcons = handleListRefresh(state.icons, state.indexes, changes);

            return { ...state, icons: newIcons };
        }
        case types.SET_ICONS_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_ICONS:
            return { ...initialState };
        default:
            return state;
    }
};
