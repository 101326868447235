import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {};
const userInitialState = {
    user: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const currentUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CURRENT_USERS:
            return { ...state, [action.payload.userId]: action.payload };
        case types.REFRESH_CURRENT_USERS: {
            const { userId, user } = action.payload;
            const newState = { ...state };

            newState[userId].user = user;

            return { ...state, ...newState };
        }
        case types.SET_CURRENT_USERS_REQUEST_STATUS:
            return { ...state, [action.payload.userId]: action.payload };
        case types.RESET_CURRENT_USERS: {
            const { userId = null } = action.payload;
            if (userId) {
                return { ...state, [userId]: userInitialState };
            }
            return { ...initialState };
        }
        default:
            return state;
    }
};
