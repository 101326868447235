import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CircleUnchecked = (props) => {
    return (
        <SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <circle
                    cx="13"
                    cy="13"
                    r="8.5"
                    stroke={props.htmlColor ? props.htmlColor : 'white'}
                    fill="transparent"
                />
            </g>
        </SvgIcon>
    );
};

export default CircleUnchecked;
