import withStyles from '@mui/styles/withStyles';
import React from 'react';
import Card from '../Card';
import { USER_FIELDS } from '../../constants';
import FormPhoneField from '../../../../../components/UI/FormPhoneField';
import FormTextField from '../../../../../components/UI/FormTextField';

const styles = () => ({
    formControlRoot: {
        marginBottom: '30px',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    textFieldInput: {
        backgroundColor: 'transparent',
    },
    textFieldLabel: {
        color: '#000',
    },
});

function InfoPanel(props) {
    const { classes, onBlur, initialEmail, hidePassword = false, defaultValues = {}, control, setValue, watch } = props;

    const email = watch('email') || '';

    function renderPhoneInput(options) {
        const { label, placeholder, name } = options;

        return (
            <FormPhoneField
                control={control}
                name={name}
                defaultValue={defaultValues[name] || ''}
                label={label}
                placeholder={placeholder}
                style={{ marginBottom: '25px' }}
                onBlur={onBlur}
                setValue={setValue}
            />
        );
    }

    function renderTextField(options) {
        const { label, placeholder, name, type = 'text' } = options;

        return (
            <FormTextField
                control={control}
                fullWidth
                variant="outlined"
                size="medium"
                onBlur={onBlur}
                label={label}
                placeholder={placeholder}
                name={name}
                type={type}
                classes={{ root: classes.formControlRoot }}
                InputProps={{
                    classes: { input: classes.textFieldInput },
                }}
                InputLabelProps={{
                    classes: { root: classes.textFieldLabel },
                }}
            />
        );
    }

    return (
        <Card>
            <Card.Title>Info</Card.Title>
            <Card.Body>
                {renderTextField({
                    id: 'Name',
                    label: 'Name',
                    placeholder: 'Name',
                    type: 'text',
                    name: USER_FIELDS.NAME,
                })}
                {renderPhoneInput({
                    id: 'WorkPhone',
                    label: 'Work Phone',
                    placeholder: '###-###-#### x####',
                    type: 'tel',
                    name: USER_FIELDS.WORK_PHONE,
                })}
                {renderPhoneInput({
                    id: 'CellPhone',
                    label: 'Cell Phone',
                    placeholder: '###-###-#### x####',
                    type: 'tel',
                    name: USER_FIELDS.CELL_PHONE,
                })}
                {renderTextField({
                    id: 'Email',
                    label: 'Email',
                    placeholder: 'Email Address',
                    type: 'email',
                    name: USER_FIELDS.EMAIL,
                })}
                {!hidePassword &&
                    initialEmail.toUpperCase() !== email.toUpperCase() &&
                    renderTextField({
                        id: 'Password',
                        label: 'Password',
                        placeholder: 'Password',
                        type: 'password',
                        name: USER_FIELDS.PASSWORD,
                    })}
            </Card.Body>
        </Card>
    );
}

export default withStyles(styles)(InfoPanel);
