import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GridModeIcon = (props) => {
    return (
        <SvgIcon width="20" height="20" viewBox="2 2 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="6.66675" y="6.66666" width="4.26661" height="4.26666" rx="0.5" fill="#fff" />
            <rect x="6.66675" y="13.0667" width="4.26661" height="4.26666" rx="0.5" fill="#fff" />
            <rect x="13.0667" y="6.66666" width="4.26661" height="4.26666" rx="0.5" fill="#fff" />
            <rect x="13.0667" y="13.0667" width="4.26661" height="4.26666" rx="0.5" fill="#fff" />
            <path opacity="0.5" d="M8.79993 4H4V8.79999" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
            <path
                opacity="0.5"
                d="M15.2001 20L20 20L20 15.2"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity="0.5"
                d="M4 15.2L4 20L8.79993 20"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity="0.5"
                d="M20 8.79999L20 4L15.2001 4"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};

export default GridModeIcon;
