import { REQUEST_STATUSES } from '../../../constants/requestStatuses';

const initialState = {
    visualization: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const currentVisualizationSelector = (propertyId, experienceId, visualizationId) => (state) =>
    state.currentVisualization[`${propertyId}${experienceId}${visualizationId}`] || initialState;
