export const APPEND_INIIALIZE = `@@firekit/COLLECTIONS@APPEND_INIIALIZE`;
export const INIIALIZE = `@@firekit/COLLECTIONS@INIIALIZE`;
export const DESTROY = `@@firekit/COLLECTIONS@DESTROY`;
export const UNWATCH = `@@firekit/COLLECTIONS@UNWATCH`;
export const CHILD_ADDED = `@@firekit/COLLECTIONS@CHILD_ADDED`;
export const CHILD_CHANGED = `@@firekit/COLLECTIONS@CHILD_CHANGED`;
export const CHILD_REMOVED = `@@firekit/COLLECTIONS@CHILD_REMOVED`;
export const CHILDREN_ADDED = `@@firekit/COLLECTIONS@CHILDREN_ADDED`;
export const CHILDREN_CHANGED = `@@firekit/COLLECTIONS@CHILDREN_CHANGED`;
export const CHILDREN_REMOVED = `@@firekit/COLLECTIONS@CHILDREN_REMOVED`;
