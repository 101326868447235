import { REQUEST_STATUSES } from '../../../constants/requestStatuses';

const initialState = {
    presentation: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const currentPresentationSelector = (propertyId) => (state) =>
    state.currentPresentation[propertyId] || initialState;
