export const SECTIONS = {
    PROPERTIES: 'properties',
    MEDIA_LIBS: 'media-libs',
    COACHES: 'coaches',
    USERS: 'users',
    MEMBERS: 'members',
    CONTACTS: 'contacts',
    EXPERIENCES: 'experiences',
    MEDIA_ITEMS: 'media-items',
    ICONS: 'icons',
    UNIVERSITY: 'university',
};
