import * as types from './types';

const docs = (state = {}, action) => {
    const { location } = action;

    switch (action.type) {
        case types.VALUE_CHANGED:
            return { ...state, [location]: action.payload };

        case types.DESTROY:
            const newState = { ...state };
            delete newState[location];
            return { ...newState };

        default:
            return state;
    }
};

export default docs;
