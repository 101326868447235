import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ThreeSixtyRedIcon = (props) => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <path
                stroke="#8F0339"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.05 15.63c-.97-.13-2-.2-3.07-.2-1.08 0-2.12.07-3.1.21"
            />
            <path
                stroke="#8F0339"
                strokeLinejoin="round"
                d="M22 18.01c0 .85-2.04 1.6-5.07 1.99V8.28C19.96 7.89 22 7.14 22 6.29V18"
            />
            <path
                stroke="#8F0339"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.3 5.16C5.02 4.47 8.27 4 12 4c3.73 0 6.98.47 8.7 1.16"
            />
            <path
                stroke="#8F0339"
                strokeLinejoin="round"
                d="M2 18.01c0 .85 2.03 1.59 5.04 1.99V8.27C4.03 7.88 2 7.14 2 6.29V18"
            />
            <path
                stroke="#8F0339"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.97 12.57h-6M8.97 10.79l2.27-2.22 1.54 2 1.31-.96.88.94M13.43 6.5c.336 0 .634.18.798.452.075.132.124.297.132.487a.93.93 0 1 1-.93-.939Z"
            />
        </SvgIcon>
    );
};

export default ThreeSixtyRedIcon;
