import * as types from './types';

const initialState = {
    selectMode: false,
    selectAll: false,
    selectedItems: {},
};

export const currentSelectedItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SELECT_MODE: {
            return { ...state, ...action.payload, changed: new Date().getTime() };
        }
        case types.SET_SELECT_ALL: {
            return { ...state, ...action.payload, changed: new Date().getTime() };
        }
        case types.ADD_CURRENT_SELECTED_ITEMS: {
            const { items, type } = action.payload;
            let newSelectedItems = { ...state.selectedItems };

            if (type === 'add') {
                newSelectedItems = { ...newSelectedItems, ...items };
            }

            if (type === 'modify') {
                Object.keys(items).forEach((id) => {
                    if (!newSelectedItems[id]) {
                        delete items[id];
                    }
                });
                newSelectedItems = { ...newSelectedItems, ...items };
            }

            return {
                ...state,
                selectedItems: newSelectedItems,
                changed: new Date().getTime(),
            };
        }
        case types.REMOVE_CURRENT_SELECTED_ITEMS: {
            const { items } = action.payload;
            const newSelectedItems = { ...state.selectedItems };
            items.forEach((id) => delete newSelectedItems[id]);

            return {
                ...state,
                selectedItems: newSelectedItems,
                changed: new Date().getTime(),
            };
        }
        case types.RESET_CURRENT_SELECTED_ITEMS: {
            return {
                ...state,
                selectedItems: {},
                selectMode: false,
                selectAll: false,
                changed: new Date().getTime(),
            };
        }
        default:
            return state;
    }
};
