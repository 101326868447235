import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ManageUsers = (props) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M17.4999 15.6434V19H6.5V15.6434C6.5 13.6831 7.74425 12.0191 9.46637 11.5123C8.77212 10.8142 8.38441 9.84836 8.38441 8.80601C8.38441 6.71175 10.0074 5 12.009 5C13.0098 5 13.9114 5.43033 14.5606 6.10929C15.2188 6.79781 15.6245 7.7541 15.6245 8.79645C15.6245 9.84836 15.2368 10.8142 14.5516 11.5123C15.2999 11.7418 15.9581 12.1817 16.4721 12.765C17.1122 13.5301 17.4999 14.5437 17.4999 15.6434Z"
            stroke="white"
            strokeMiterlimit="10"
            strokeLinejoin="round"
        />
        <path
            d="M16.8383 10.9761C17.1038 10.6276 17.4856 10.3703 17.9254 10.2458C17.5685 9.91387 17.3694 9.44914 17.3694 8.94292C17.3694 7.93877 18.1909 7.12549 19.2117 7.12549C19.7179 7.12549 20.1826 7.33296 20.5063 7.65661C20.8465 7.98856 21.0457 8.44499 21.0457 8.94292C21.0457 9.44914 20.8465 9.90557 20.498 10.2458C20.8797 10.3537 21.2117 10.5695 21.4689 10.8433C21.8009 11.2085 22.0001 11.6898 22.0001 12.2209V13.8309H18.664"
            stroke="white"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.16179 10.9761C6.89624 10.6275 6.52279 10.3703 6.08296 10.2541C6.43981 9.92214 6.63898 9.45741 6.63898 8.95119C6.63898 7.94704 5.8174 7.13376 4.79666 7.13376C4.29044 7.13376 3.82571 7.34123 3.50207 7.66488C3.16182 7.98853 2.95435 8.45326 2.95435 8.95119C2.95435 9.45741 3.15352 9.91384 3.50207 10.2541C3.12032 10.362 2.78838 10.5694 2.53112 10.8516C2.19917 11.2167 2 11.6981 2 12.2292V13.8392H5.33608"
            stroke="white"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SvgIcon>
);

export default ManageUsers;
