import { defineMessages } from 'react-intl';

const messages = defineMessages({
    app_name: 'React Most Wanted',
    dashboard: 'Panel',
    about: 'Acerca',
    page_not_found_demo: 'Página no encontrada demo',
    404: '404',
    warning_404_message: 'Página no encontrada',
    warning_404_description: 'Disculpa, pero la página que buscas no existe.',
    settings: 'Ajustes',
    language: 'Idioma',
    theme: 'Tema',
    responsive: 'Responsivo',
    en: 'English',
    de: 'Deutsch',
    bs: 'Bosanski',
    ru: 'Русский',
    es: 'Español',
    dark: 'Oscuro',
    light: 'Claro',
    ics: 'ICS',
    sign_out: 'Salir',
    sign_in: 'Ingresar',
    sign_up: 'Registrarse',
    sign_in_with_google: 'Ingresar con Google',
    sign_in_with_facebook: 'Ingresar con Facebook',
    sign_in_with_twitter: 'Ingresar con Twitter',
    sign_in_with_github: 'Ingresar con Github',
    'link_with_google.com': 'Enlazar con Google',
    'link_with_facebook.com': 'Enlazar con Facebook',
    'link_with_twitter.com': 'Enlazar con Twitter',
    'link_with_github.com': 'Enlazar con Github',
    my_account: 'Mi cuenta',
    name: 'Nombre',
    email: 'E-Mail',
    password: 'Contraseña',
    new_password: 'Nueva Contraseña',
    confirm_password: 'Confirmar Contraseña',
    forgort_password: '¿Olvido la Contraseña?',
    reset_password: 'Restablecer Contraseña',
    change_password: 'Cambiar Contraseña',
    change_photo: 'Cambiar Foto',
    change_email: 'Cambiar E-Mail',
    reset_password_hint: 'Ingrese su E-Mail',
    save: 'Guardar',
    delete_account: 'Eliminar cuenta',
    select_file: 'Seleccionar Archivo',
    cancel: 'Cancelar',
    submit: 'Enviar',
    delete: 'Borrar',
    ok: 'OK',
    delete_account_dialog_title: 'Eliminar cuenta?',
    delete_account_dialog_message: 'Su cuenta será eliminada y perderás toda tu información!',
    email_not_verified: 'Correo electrónico sin verificar!',
    email_verified: 'Correo electrónico verificado',
    send_verification_email: 'Enviar correo de verificación',
    send_verification_email_again: 'Enviar correo de verificación de nuevo',
    tasks: 'Tareas',
    create_task: 'Crear tarea',
    edit_task: 'Editar tarea',
    users: 'Usuario',
    edit: 'Editar',
    online: 'Conectado',
    offline: 'Desconectado',
    no_connection_warning: '¡Sin conexión!',
    title_label: 'Título',
    title_hint: 'Ingresar título',
    no_connection: 'No hay conexión',
    delete_task_title: '¿Borrar tarea?',
    delete_task_message: 'La tarea será eliminada!',
    error: 'Error!',
    companies: 'Compañias',
    create_company: 'Crear compañia',
    edit_company: 'Editar  compañia',
    delete_company_title: '¿Eliminar compañia?',
    delete_company_message: '¡La compañia será eliminada!',
    full_name_label: 'Nombre completo',
    full_name_hint: 'Ingrese su nombre completo',
    vat_label: 'UID',
    vat_hint: 'Ingrese UID',
    description_label: 'Descripción',
    description_hint: 'Ingrese la Descripción',
    name_label: 'Nombre',
    name_hint: 'Ingrese el nombre',
    public_chats: 'Chat público',
    delete_message_title: '¿Borrar mensaje?',
    delete_message_message: '¡El mensaje será borrado!',
    users_count_title: '{number} Usuarios',
    user_registrationg_graph_label: 'Registros de usuarios',
    required: 'Requerido',
    facebook: 'Facebook',
    github: 'Github',
    twitter: 'Twitter',
    phone: 'Teléfono',
    google: 'Google',
    facebook_color: '#303F9F',
    github_color: '#263238',
    twitter_color: '#36A2EB',
    phone_color: '#90A4AE',
    google_color: '#EA4335',
    password_color: '#4CAF50',
    chats: 'Chats',
    write_message_hint: 'Escribir mensaje...',
    load_more_label: 'Más...',
    my_location: 'Mi ubicación',
    select_user: 'Seleccionar usuario',
    operator_like_label: 'like',
    operator_notlike_label: 'not like',
    operator_equal_label: 'equal',
    operator_notequal_label: 'not equal',
    operator_novalue_label: 'no value',
    administration: 'Administración',
    roles: 'Roles',
    grants: 'Concesiones',
    private: 'Privado',
    public: 'Público',
    grant_read_companies: 'Leer compañias',
    grant_create_company: 'Crear compañias',
    grant_edit_company: 'Editar compañias',
    grant_delete_company: 'Borrar compañias',
    is_admin_label: 'Administrador',
    predefined_messages: 'Mensajes predefinidos',
    delete_predefined_chat_message_title: '¿Borrar mensaje predefinido?',
    delete_predefined_chat_message_message: '¡El mensaje predefinido sera borrado!',
    select_field: 'Seleccionar campo',
    sorting: 'Orden',
    filters: 'Filtros',
    filter: 'Filtro',
    add_filter: 'Agregar filtro',
    delete_filter: 'Borrar filter',
    change_sort_orientation: 'Cambiar orientación',
    enable_case_sensitivity: 'Sensibilidad de mayusculas',
    hint_autocomplete: 'Seleccionar',
    enter_query_text: 'Ingresar texto',
    email_label: 'Email',
    close_filter: 'Cerrar filtro',
    open_filter: 'Abrir filtro',
    select_operator: 'Seleccione operador',
    not_match_found: 'No se encontraron coincidencias',
    edit_user: 'Editar usuario',
    firestore: 'Firestore',
    hot_dog_status: 'Hot dog status',
    user_label_search: 'Buscar usuario',
    document: 'Documento',
    collection: 'Colección',
    infinitelist: 'Infinitelist',
    activate: 'activar',
    deactivate: 'desactivar',
});

export default messages;
