import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CalendarRedIcon = (props) => {
    return (
        <SvgIcon width="17px" height="17px" viewBox="0 0 17 17" {...props}>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                <g id="Icon/Calendar/Red" transform="translate(-6.000000, -5.000000)" stroke="#8F0339">
                    <g id="Group-4" transform="translate(7.000000, 6.000000)">
                        <line x1="3" y1="15" x2="3" y2="4" id="Path" />
                        <line x1="6" y1="15" x2="6" y2="4" id="Path-Copy" />
                        <line x1="9" y1="15" x2="9" y2="4" id="Path-Copy-2" />
                        <line x1="12" y1="15" x2="12" y2="4" id="Path-Copy-3" />
                        <rect id="Rectangle" x="0" y="0" width="15" height="15" />
                    </g>
                    <line
                        x1="14.5"
                        y1="17.5"
                        x2="14.5"
                        y2="2.5"
                        id="Path"
                        transform="translate(14.500000, 10.000000) rotate(-270.000000) translate(-14.500000, -10.000000) "
                    />
                    <line
                        x1="14.5"
                        y1="21"
                        x2="14.5"
                        y2="6"
                        id="Path-Copy"
                        transform="translate(14.500000, 13.500000) rotate(-270.000000) translate(-14.500000, -13.500000) "
                    />
                    <line
                        x1="14.5"
                        y1="24.5"
                        x2="14.5"
                        y2="9.5"
                        id="Path-Copy-2"
                        transform="translate(14.500000, 17.000000) rotate(-270.000000) translate(-14.500000, -17.000000) "
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

export default CalendarRedIcon;
