import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // change es to lib for jest/// default: localStorage if web, AsyncStorage if react-native
import { reducers } from './reducers';
import { reduxLogger, reduxPersist, reduxDevTools, currentEnv } from '../config/envSettings';
import config from '../config/config';

const configureStore = (appConfig) => {
    const middlewares = [thunk];

    if (reduxLogger()) {
        middlewares.push(createLogger({})); // DEV middlewares
    }

    const composeEnhancers =
        reduxDevTools() && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  trace: true,
                  // Specify extensions options like
                  // name, actionsBlacklist, actionsCreators, serialize...
              })
            : compose;

    const init = appConfig.initial_state;

    const enhancer = composeEnhancers(applyMiddleware(...middlewares));

    const key = `TTX:${currentEnv()}`;

    const persistorConfig = {
        key,
        storage,
        blacklist: [
            // 'auth',
            'collections',
            'connection',
            'initialization',
            'docs',
            'form',
            'messaging',
            'simpleValues',
            'experienceLanguages',
            'visualBuilderData',
            'mainHeaderData',
            'experienceElementsData',
            'propertySettingsData',
            'filters',
            // v2
            'properties',
            'mediaLibs',
            'coaches',
            'users',
            'experiences',
            'mediaItems',
            'members',
            'contacts',
            'contents',
            'elements',
            'contentElements',
            'currentProperty',
            'currentPresentation',
            'currentVisualization',
            'currentMediaLib',
            'currentMember',
            'currentExperience',
            'singleShareExperience',
            'currentExperiences',
            'currentMediaItem',
            'currentElement',
            'currentShare',
            'currentUser',
            'currentUsers',
            'univCategories',
            'univVideos',
            'currentUnivVideo',
            'currentMembers',
            'currentContact',
            'contactNotes',
            'contactExperiences',
            'contactEvents',
            'messages',
            'searchedExperiences',
            'recentExperiences',
            'templateExperiences',
            'checkUrls',
            'currentMediaItems',
            'currentSelectedItems',
            'currentSharedExperience',
            'uploadProgress',
        ],
    };

    const reducer = persistReducer(persistorConfig, reducers);

    const store = createStore(reducer, init, enhancer);

    if (reduxPersist()) {
        try {
            persistStore(store);
        } catch (e) {
            console.log(e);
        }
    }

    return store;
};

export default configureStore(config);
