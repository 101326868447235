import { defineMessages } from 'react-intl';

const messages = defineMessages({
    app_name: 'React Meist Gesucht',
    dashboard: 'Übersicht',
    about: 'Über',
    page_not_found_demo: 'Seite nicht gefunden demo',
    404: '404',
    warning_404_message: '404 Seite nicht gefunden',
    warning_404_description: 'Es tut uns leid aber die angeforderte Seite existiert nicht.',
    settings: 'Einstellungen',
    language: 'Sprache',
    theme: 'Thema',
    responsive: 'Responsive',
    en: 'English',
    de: 'Deutsch',
    bs: 'Bosanski',
    ru: 'Русский',
    es: 'Español',
    dark: 'Dark',
    light: 'Light',
    ics: 'ICS',
    sign_out: 'Abmelden',
    sign_in: 'Anmelden',
    sign_up: 'Registrieren',
    sign_in_with_google: 'Mit Google anmelden',
    sign_in_with_facebook: 'Mit Facebook anmelden',
    sign_in_with_twitter: 'Mit Twitter anmelden',
    sign_in_with_github: 'Mit Github anmelden',
    'link_with_google.com': 'Mit Google verbinden',
    'link_with_facebook.com': 'Mit Facebook verbinden',
    'link_with_twitter.com': 'Mit Twitter verbinden',
    'link_with_github.com': 'Mit Github verbinden',
    my_account: 'Mein Konto',
    name: 'Name',
    email: 'E-Mail',
    password: 'Passwort',
    new_password: 'Neues Passwort',
    confirm_password: 'Passwort bestätigen',
    forgort_password: 'Passwort vergessen?',
    reset_password: 'Passwort zurücksetzten',
    change_password: 'Passwort ändern',
    change_email: 'E-Mail ändern',
    change_photo: 'Foto ändern',
    reset_password_hint: 'Ihre E-Mail eingeben',
    save: 'Speichern',
    delete_account: 'Konto löschen',
    select_file: 'Datei auswählen',
    cancel: 'Abbrechen',
    submit: 'Bestätigen',
    delete: 'Löschen',
    ok: 'OK',
    delete_account_dialog_title: 'Konto löschen?',
    delete_account_dialog_message: 'Dein Konto wird gelöscht und mit ihm alle Daten!',
    email_not_verified: 'E-Mail ist nicht verifiziert!',
    email_verified: 'E-Mail ist verifiziert',
    send_verification_email: 'Verifizierungs E-Mail senden',
    send_verification_email_again: 'Verifizierungs E-Mail wieder senden',
    tasks: 'Aufgaben',
    create_task: 'Aufgabe erstellen',
    edit_task: 'Aufgabe bearbeiten',
    users: 'Benutzer',
    edit: 'Bearbeiten',
    online: 'Online',
    offline: 'Offline',
    no_connection_warning: 'Keine Verbindung!',
    title_label: 'Titel',
    title_hint: 'Titel eingeben',
    no_connection: 'Keine Verbindung',
    delete_task_title: 'Aufgabe löschen?',
    delete_task_message: 'Aufgabe wird gelöscht!',
    error: 'Fehler!',
    companies: 'Unternehmen',
    create_company: 'Unternehmen erstellen',
    edit_company: 'Unternehmen bearbeiten',
    delete_company_title: 'Unternehmen löschen?',
    delete_company_message: 'Unternehmen wird gelöscht!',
    full_name_label: 'Voller Name',
    full_name_hint: 'Vollen Namen eingeben',
    vat_label: 'UID',
    vat_hint: 'UID eingeben',
    description_label: 'Beschreibung',
    description_hint: 'Beschreibung eingeben',
    name_label: 'Name',
    name_hint: 'Name eingeben',
    public_chats: 'Öffentlicher Chat',
    delete_message_title: 'Nachricht löschen?',
    delete_message_message: 'Die Nachrricht wird gelöscht!',
    users_count_title: '{number} Benutzer',
    user_registrationg_graph_label: 'Benutzer registrierungen',
    required: 'Erforderlich',
    facebook: 'Facebook',
    github: 'Github',
    twitter: 'Twitter',
    phone: 'Phone',
    google: 'Google',
    facebook_color: '#303F9F',
    github_color: '#263238',
    twitter_color: '#36A2EB',
    phone_color: '#90A4AE',
    google_color: '#EA4335',
    password_color: '#4CAF50',
    chats: 'Chats',
    write_message_hint: 'Eine Nachricht schreiben...',
    load_more_label: 'Mehr...',
    my_location: 'Mein Standort',
    select_user: 'Benutzer auswählen',
    operator_like_label: 'wie',
    operator_notlike_label: 'nicht wie',
    operator_equal_label: 'gleich',
    operator_notequal_label: 'nicht equal',
    operator_novalue_label: 'kein Wert',
    administration: 'Administration',
    roles: 'Rollen',
    grants: 'Berechtigungen',
    private: 'Private',
    public: 'Öffentlich',
    grant_read_companies: 'Unternehmen lesen',
    grant_create_company: 'Unternehmen erstellen',
    grant_edit_company: 'Unternehmen bearbeiten',
    grant_delete_company: 'Unternehmen löschen',
    is_admin_label: 'Administrator',
    predefined_messages: 'Vorgefertigte Nachrichten',
    delete_predefined_chat_message_title: 'Vorgefertigte Nachricht löschen?',
    delete_predefined_chat_message_message: 'Vorgefertigte Nachricht wird gelöscht!',
    select_field: 'Feld auswählen',
    sorting: 'Sortierung',
    filters: 'Filter',
    filter: 'Filter',
    add_filter: 'Filter hinzufügen',
    delete_filter: 'Filter löschen',
    change_sort_orientation: 'Anordnung ändern',
    enable_case_sensitivity: 'Grosschreibung',
    hint_autocomplete: 'Auswählen',
    enter_query_text: 'Tekst eingeben',
    email_label: 'Email',
    close_filter: 'Filter schliesen',
    open_filter: 'Filter öffnen',
    select_operator: 'Operator auswählen',
    not_match_found: 'Nichst gefunden',
    edit_user: 'Benutzer bearbeiten',
    firestore: 'Firestore',
    hot_dog_status: 'Hot dog status',
    user_label_search: 'Suchen',
    document: 'Document',
    collection: 'Collection',
    infinitelist: 'Infinitelist',
    deactivate: 'deaktivieren',
    activate: 'aktivieren',
});

export default messages;
