import { defineMessages } from 'react-intl';

const messages = defineMessages({
    app_name: 'React Самый Нужный',
    dashboard: 'Дашборд',
    about: 'О нас',
    page_not_found_demo: 'Страница не найдена Демо',
    404: '404',
    warning_404_message: '404 Страница не найдена',
    warning_404_description: 'К сожалению, страница, которую Вы запрашиваете, не существует.',
    settings: 'Настроики',
    language: 'Язык',
    theme: 'Темы',
    responsive: 'Адаптивный',
    en: 'English',
    de: 'Deutsch',
    bs: 'Bosanski',
    ru: 'Русский',
    es: 'Español',
    dark: 'Темная',
    light: 'Светла',
    ics: 'ICS',
    sign_out: 'Выход',
    sign_in: 'Вход',
    sign_up: 'Зарегистрироваться',
    sign_in_with_google: 'Войти через Гугл',
    sign_in_with_facebook: 'Войти через Фейсбук',
    sign_in_with_twitter: 'Войти через Твиттер',
    sign_in_with_github: 'Войти через Гитхаб',
    'link_with_google.com': 'Привязанные к аккаунту Гугл',
    'link_with_facebook.com': 'Привязанные к аккаунту Фейсбук',
    'link_with_twitter.com': 'Привязанные к аккаунту Твиттер',
    'link_with_github.com': 'Привязанные к аккаунту Гитхаб',
    my_account: 'Мой аккаунт',
    name: 'Имя',
    email: 'e-мейл',
    password: 'Пароль',
    new_password: 'Новый пароль',
    confirm_password: 'Повторите пароль',
    forgort_password: 'Забыли пароль?',
    reset_password: 'Сброс пароля',
    change_password: 'Изменить пароль',
    change_photo: 'Сменить фото',
    change_email: 'Сменить e-мейл',
    reset_password_hint: 'Введите ваш e-мейл',
    save: 'Сохранить',
    delete_account: 'Удалить аккаунт',
    select_file: 'Выберите файл',
    cancel: 'Аннулировать',
    submit: 'Отправить',
    delete: 'Удалить',
    ok: 'OK',
    delete_account_dialog_title: 'Удалить Ваш аккаунт?',
    delete_account_dialog_message: 'Ваш аккаунт будет удален и вы потеряете все ваши данные!',
    email_not_verified: 'E-mail не подтвержден!',
    email_verified: 'E-mail подтвержден',
    send_verification_email: 'Отправить письмо с подтверждением',
    send_verification_email_again: 'Выслать письмо для активации повторно',
    tasks: 'Задачи',
    create_task: 'Создать задачу',
    edit_task: 'Редактировать задачу',
    users: 'Пользователи',
    edit: 'Редактировать',
    online: 'Онлайн',
    offline: 'Офлайн',
    no_connection_warning: 'Нет соединения!',
    title_label: 'Заголовок',
    title_hint: 'Введите заголовок',
    no_connection: 'Нет соединения',
    delete_task_title: 'Удалить задачу?',
    delete_task_message: 'Задача будет удалена!',
    error: 'Ошибка!',
    companies: 'Компании',
    create_company: 'Создать компанию',
    edit_company: 'Редактировать компанию',
    delete_company_title: 'Удалить компанию?',
    delete_company_message: 'Компания будет удалена!',
    full_name_label: 'Полное название',
    full_name_hint: 'Введите полное название',
    vat_label: 'UID',
    vat_hint: 'Введите UID',
    description_label: 'Описание',
    description_hint: 'Введите описание',
    name_label: 'Имя',
    name_hint: 'Введите Имя',
    public_chats: 'Публичный чат',
    delete_message_title: 'Удалить сообщение?',
    delete_message_message: 'Сообщение будет удалено!',
    users_count_title: '{количество} Пользователей',
    user_registrationg_graph_label: 'Регистрация пользователей',
    required: 'Обязательно',
    facebook: 'Фейсбук',
    github: 'Гитхаб',
    twitter: 'Твиттер',
    phone: 'Телефон',
    google: 'Гугл',
    facebook_color: '#303F9F',
    github_color: '#263238',
    twitter_color: '#36A2EB',
    phone_color: '#90A4AE',
    google_color: '#EA4335',
    password_color: '#4CAF50',
    chats: 'Чаты',
    write_message_hint: 'Написать сообщение...',
    load_more_label: 'Больше...',
    my_location: 'Моё местоположение',
    select_user: 'Выберите пользователя',
    operator_like_label: 'нравиться',
    operator_notlike_label: 'не нравишься',
    operator_equal_label: 'равный',
    operator_notequal_label: 'не равный',
    operator_novalue_label: 'нет значения',
    administration: 'Администрация',
    roles: 'Роли',
    grants: 'Гранты',
    private: 'Приватный',
    public: 'Публичный',
    grant_read_companies: 'Посмотреть Компании',
    grant_create_company: 'Создать компанию',
    grant_edit_company: 'Редактировать компанию',
    grant_delete_company: 'Удалить компанию',
    is_admin_label: 'Администратор',
    predefined_messages: 'Готовые Сообщения',
    delete_predefined_chat_message_title: 'Удалить предустановленные сообщения?',
    delete_predefined_chat_message_message: 'Предустановленное сообщение будет удалено!',
    select_field: 'Выберите опцию',
    sorting: 'Сортировать',
    filters: 'Фильтры',
    filter: 'Фильтр',
    add_filter: 'Добавить фильтр',
    delete_filter: 'Удалить фильтр',
    change_sort_orientation: 'Изменение ориентации',
    enable_case_sensitivity: 'Чувствительность к регистру',
    hint_autocomplete: 'Выберите',
    enter_query_text: 'Введите текст',
    email_label: 'Эмейл',
    close_filter: 'Закрыть фильтр',
    open_filter: 'Открыть фильтр',
    select_operator: 'Выбoр оператора',
    not_match_found: 'Совпадений не найдено',
    edit_user: 'Редактирование пользователя',
    firestore: ' Фиресторе',
    hot_dog_status: 'Статус хот-дога',
    user_label_search: 'Поиск людей',
    document: 'Документ',
    collection: 'Коллекция',
    infinitelist: 'Бесконечный скроллинг',
    mark_chat_as_unread: 'Пометить как непрочитанное',
    delete_chat: 'Удалить чат',
    search: 'Поиск',
    update_available: 'Доступна новая версия этого приложения.',
    load_update: 'Обновить',
    enable_notifications_message: 'Включить уведомления?',
    enable: 'Включить',
    no_thanks: 'Нет, Спасибо',
    creation_time: 'Время создания',
    night_mode: 'Ночной режим',
    day_mode: 'Дневной режим',
    default: 'Стандарт',
    red: 'Красная',
    green: 'Зеленая',
    notifications: 'Уведомления',
    disable_notifications_dialog_title: 'Отключить уведомления',
    disable_notifications_dialog_message: 'Все уведомления на всех ваших устройствах будут отключены!',
    update_title: 'Доступно обновление!',
    update_message: 'Нажмите здесь, чтобы получить новую версию.',
    install: 'Установить',
    deactivate: 'деактивировать',
    activate: 'активировать',
});

export default messages;
