import React from 'react';

const EntireExperienceDesktop = (props) => {
    return (
        <svg width="220" height="142" viewBox="0 0 220 142" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="1.31818" y="0.5" width="217.364" height="141" rx="2.5" stroke="white" />
            <rect x="1.31818" y="8.77274" width="217.364" height="13.7273" stroke="white" />
            <rect x="178.135" y="11" width="38.1692" height="9.44754" rx="1.5" fill="#C90451" stroke="white" />
            <circle cx="187.083" cy="15.7238" r="1.68951" fill="white" />
            <rect x="190.462" y="14.0342" width="20.2741" height="3.37902" rx="1" fill="white" />
            <path
                d="M124.178 54.0639C173.75 26.1913 209.51 44.7093 216.041 49.0361C216.555 49.3768 216.818 49.9466 216.818 50.5635V137C216.818 138.105 215.923 139 214.818 139H5.81812C4.71355 139 3.81812 138.105 3.81812 137V100.006C3.81812 98.3835 5.68091 97.402 7.13197 98.1275C31.6888 110.407 74.3597 82.0747 124.178 54.0639Z"
                fill="#595959"
            />
            <path
                d="M79.9156 84.7726C31.2261 60.5433 10.2872 57.658 4.76435 59.4247C4.13768 59.6251 3.81818 60.2573 3.81818 60.9152V137C3.81818 138.105 4.71361 139 5.81818 139H214.818C215.923 139 216.818 138.105 216.818 137V75.9294C216.818 74.3581 215.101 73.3818 213.742 74.1701C188.005 89.0944 129.948 109.67 79.9156 84.7726Z"
                fill="#888888"
            />
        </svg>
    );
};

export default EntireExperienceDesktop;
