import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    mediaItems: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
    noResults: false,
};

export const currentMediaItemsReducer = (state = {}, action) => {
    switch (action.type) {
        case types.GET_CURRENT_MEDIA_ITEMS: {
            const { propertyId, mediaItems, status, error } = action.payload;
            return {
                ...state,
                [propertyId]: {
                    mediaItems,
                    status,
                    error,
                    noResults: !Object.keys(mediaItems).length,
                },
            };
        }
        case types.REFRESH_CURRENT_MEDIA_ITEMS: {
            const { propertyId, changes } = action.payload;
            const { mediaItems = {} } = state[propertyId] || {};
            const newMediaItems = { ...mediaItems };

            Object.keys(changes).forEach((mediaItemId) => {
                newMediaItems[mediaItemId] = changes[mediaItemId];
            });

            return {
                ...state,
                [propertyId]: {
                    ...(state[propertyId] || {}),
                    mediaItems: newMediaItems,
                },
            };
        }
        case types.SET_CURRENT_MEDIA_ITEMS_REQUEST_STATUS: {
            const { propertyId, status } = action.payload;
            return {
                ...state,
                [propertyId]: { ...initialState, ...(state[propertyId] || {}), status },
            };
        }
        case types.RESET_CURRENT_MEDIA_ITEMS: {
            const { propertyId } = action.payload;

            if (propertyId) {
                return { ...state, [propertyId]: initialState };
            }

            return { ...initialState };
        }
        default:
            return state;
    }
};
