import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImageIcon = (props) => {
    const { style, htmlColor, ...rest } = props;
    return (
        <SvgIcon
            width="30"
            height="32"
            viewBox="0 0 30 32"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                height: '1em',
                width: '0.9443359375em',
                fontSize: '1em',
                ...style,
            }}
            fill={htmlColor}
            {...rest}
        >
            <path d="M18.658 0c0.44-0.001 0.807 0.323 0.883 0.751l0.014 0.165v8.489c0 0.506-0.401 0.916-0.895 0.916-0.439 0-0.804-0.324-0.88-0.752l-0.014-0.165-0.001-7.571-9.985 0.031-5.992 7.194v19.33l1.034 0c0.439 0 0.804 0.324 0.88 0.752l0.014 0.165c0 0.45-0.316 0.824-0.734 0.901l-0.161 0.015h-1.928c-0.439 0-0.804-0.324-0.88-0.752l-0.014-0.165v-20.582c0-0.163 0.043-0.323 0.122-0.462l0.092-0.132 6.473-7.773c0.135-0.163 0.321-0.27 0.523-0.307l0.154-0.014 11.293-0.035z" />
            <path d="M8.889 0v7.91c0 0.48-0.314 0.88-0.728 0.963l-0.16 0.016h-6.223v-1.957l5.334-0.001 0.002-6.93h1.776z" />
            <path d="M15.278 18.114l-9.733 12.321c-0.488 0.618-0.078 1.564 0.678 1.564h23.11c0.737 0 1.153-0.907 0.702-1.532l-6.585-9.122-0.114-0.131c-0.329-0.312-0.835-0.323-1.177 0.001l-2.343 2.222-3.113-5.222c-0.322-0.54-1.040-0.591-1.426-0.102zM15.854 20.326l3.027 5.075 0.098 0.136c0.321 0.371 0.872 0.413 1.239 0.064l2.407-2.288 4.901 6.788h-19.392l7.72-9.774z" />
            <path d="M24 10.667c-2.455 0-4.444 1.99-4.444 4.444s1.99 4.444 4.444 4.444c2.455 0 4.444-1.99 4.444-4.444s-1.99-4.444-4.444-4.444zM24 12.618c1.377 0 2.493 1.116 2.493 2.493s-1.116 2.493-2.493 2.493c-1.377 0-2.493-1.116-2.493-2.493s1.116-2.493 2.493-2.493z" />
        </SvgIcon>
    );
};

export default ImageIcon;
