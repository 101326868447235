import React from 'react';
import { getLogoSvg } from '../../utils/image';

function LoadingSplash() {
    const logoSvg = getLogoSvg();

    return (
        <div
            style={{
                position: 'fixed',
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
                width: '100%',
                zIndex: 1204,
                background: '#fff',
            }}
        >
            <div
                className="container"
                style={{
                    height: '100%',
                    display: 'flex',
                    flexFlow: 'column wrap',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
            >
                {logoSvg ? (
                    <img
                        src={logoSvg}
                        alt="Logo"
                        className="loader"
                        style={{ height: '80px', width: '100%', maxWidth: '300px' }}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    );
}

export default LoadingSplash;
