import { REQUEST_STATUSES } from '../constants/requestStatuses';

export const getListIndexes = (list) => list.reduce((acc, el, index) => ({ ...acc, [el.id]: index }), {});

export const getNewList = (query, list = [], newList = []) => (!query.offset ? newList : [...list, ...newList]);

const allowedStatuses = [REQUEST_STATUSES.IDLE, REQUEST_STATUSES.FAILED];

export const isRequestAllowed = (status) => allowedStatuses.indexOf(status) > -1;

export const handleListRefresh = (list = [], indexes = {}, changes = {}) => {
    const newList = [...list];
    Object.keys(changes).forEach((id) => {
        if (indexes.hasOwnProperty(id)) {
            newList[indexes[id]] = changes[id];
        }
    });

    return newList;
};

export const getDocumentName = (name, count) => {
    let str = `${name === 'media-items' ? 'content items' : name}`;
    if (count < 2) {
        str = str.slice(0, str.length - 1);
    }
    return str;
};

export const getQueueActionMessage = (name, action, data, options = {}) => ({
    action: `${name}_${action}_${Object.keys(data).length > 1 ? 'multiple' : 'single'}`,
    options,
});
