import { Button as MUIButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

const styles = () => ({
    panelButton: {
        padding: '6px 16px',
        minWidth: '83px',
    },
});

function Button(props) {
    const { classes, onClick, children } = props;

    return (
        <MUIButton color="primary" variant="contained" className={classes.panelButton} onClick={onClick}>
            {children}
        </MUIButton>
    );
}

export default withStyles(styles)(Button);
