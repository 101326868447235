import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Drafts = (props) => {
    return (
        <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0883 2.32462L17.7178 0.954102L15.9252 2.74663L5.05865 13.6132L2.82483 18.5881L7.88708 16.4417L20.5462 3.78253L19.0883 2.32462Z"
                stroke={props.htmlColor ? props.htmlColor : 'rgba(0,0,0,.5)'}
                strokeLinecap="round"
                fill="none"
            />
            <path
                d="M14.7805 4.68384L17.4412 7.45322"
                stroke={props.htmlColor ? props.htmlColor : 'rgba(0,0,0,.5)'}
                fill="none"
            />
            <path
                d="M3.29293 16.793L4.70714 18.2072"
                stroke={props.htmlColor ? props.htmlColor : 'rgba(0,0,0,.5)'}
                fill="none"
            />
            <path
                d="M4.56663 14.1052L7.43359 16.8951"
                stroke={props.htmlColor ? props.htmlColor : 'rgba(0,0,0,.5)'}
                fill="none"
            />
        </SvgIcon>
    );
};

export default Drafts;
