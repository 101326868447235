import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RemoveIcon = (props) => {
    return (
        <SvgIcon width="13" height="2" viewBox="0 0 13 2" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect
                x="12.332"
                y="0.635742"
                width="0.833334"
                height="11.6667"
                rx="0.416667"
                transform="rotate(90 12.332 0.635742)"
                fill={props.htmlColor || 'white'}
            />
        </SvgIcon>
    );
};

export default RemoveIcon;
