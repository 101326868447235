import React from 'react';
import { Checkbox, LabelText, FormControlLabel } from '../../..';
import { NOTIFICATION_OPTIONS } from '../../../../../../constants';

function Email(props) {
    const { name, email, values, onChange } = props;

    const labelMarkup = <LabelText email={email} option={NOTIFICATION_OPTIONS.EMAIL} />;

    const controlMarkup = <Checkbox name={name} values={values} onChange={onChange} />;

    return <FormControlLabel label={labelMarkup} control={controlMarkup} />;
}

export default Email;
