import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Preview = (props) => {
    return (
        <SvgIcon width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.996094 1L0.996094 13L8.99609 7L0.996094 1Z"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
                fill="none"
            />
        </SvgIcon>
    );
};

export default Preview;
