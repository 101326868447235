import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FullscreenModeIcon = (props) => {
    return (
        <SvgIcon width="20" height="20" viewBox="2 2 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect
                opacity="0.5"
                x="6.66675"
                y="6.66666"
                width="4.26667"
                height="4.26667"
                stroke="#fff"
                strokeLinejoin="round"
            />
            <rect
                opacity="0.5"
                x="6.66675"
                y="13.0667"
                width="4.26667"
                height="4.26667"
                stroke="#fff"
                strokeLinejoin="round"
            />
            <rect
                opacity="0.5"
                x="13.0667"
                y="6.66666"
                width="4.26667"
                height="4.26667"
                stroke="#fff"
                strokeLinejoin="round"
            />
            <rect
                opacity="0.5"
                x="13.0667"
                y="13.0667"
                width="4.26667"
                height="4.26667"
                stroke="#fff"
                strokeLinejoin="round"
            />
            <path d="M8.8 4H4V8.8" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M15.2 20L20 20L20 15.2"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 15.2L4 20L8.8 20"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 8.8L20 4L15.2 4"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};

export default FullscreenModeIcon;
