import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    properties: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
    noResults: false,
};

export const propertiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PROPERTIES: {
            const { properties, status, error, query } = action.payload;
            const newProperties = getNewList(query, state.properties, properties);
            const indexes = getListIndexes(newProperties);
            return {
                ...state,
                properties: newProperties,
                status,
                error,
                indexes,
                noResults: !newProperties.length,
            };
        }
        case types.REFRESH_PROPERTIES: {
            const { changes } = action.payload;

            const newProperties = handleListRefresh(state.properties, state.indexes, changes);

            return { ...state, properties: newProperties };
        }
        case types.SET_PROPERTIES_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_PROPERTIES:
            return { ...initialState };
        default:
            return state;
    }
};
