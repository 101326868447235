import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowDownIcon = (props) => {
    return (
        <SvgIcon width="12px" height="5px" viewBox="0 0 12 5" {...props}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="GM-Settings-icons"
                    transform="translate(-1171.000000, -630.000000)"
                    stroke={props.htmlColor || '#979797'}
                >
                    <polyline id="Path-2-Copy-4" points="1172 630 1177 635 1182 630" />
                </g>
            </g>
        </SvgIcon>
    );
};

export default ArrowDownIcon;
