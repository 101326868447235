import { defineMessages } from 'react-intl';

const messages = defineMessages({
    current_locale: 'en-US',
    app_name: 'React Most Wanted',
    dashboard: 'Dashboard',
    about: 'About',
    page_not_found_demo: 'Page not found demo',
    404: '404',
    warning_404_message: '404 Page not found',
    warning_404_description: 'We are sorry but the page you are looking for does not exist.',
    settings: 'Settings',
    language: 'Language',
    theme: 'Theme',
    responsive: 'Responsive',
    en: 'English',
    de: 'Deutsch',
    bs: 'Bosanski',
    ru: 'Русский',
    es: 'Español',
    dark: 'Dark',
    light: 'Light',
    ics: 'ICS',
    sign_out: 'Sign out',
    sign_in: 'Sign in',
    sign_up: 'Sign up',
    sign_in_with_google: 'Sign in with Google',
    sign_in_with_facebook: 'Sign in with Facebook',
    sign_in_with_twitter: 'Sign in with Twitter',
    sign_in_with_github: 'Sign in with Github',
    'link_with_google.com': 'Link with Google',
    'link_with_facebook.com': 'Link with Facebook',
    'link_with_twitter.com': 'Link with Twitter',
    'link_with_github.com': 'Link with Github',
    my_account: 'My account',
    name: 'Name',
    email: 'E-Mail',
    password: 'Password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    forgort_password: 'Forgot Password?',
    reset_password: 'Reset Password',
    change_password: 'Change Password',
    change_photo: 'Change Photo',
    change_email: 'Change E-Mail',
    reset_password_hint: 'Enter your E-Mail',
    save: 'Save',
    delete_account: 'Delete account',
    select_file: 'Select file',
    cancel: 'Cancel',
    submit: 'Submit',
    delete: 'Delete',
    ok: 'OK',
    delete_account_dialog_title: 'Delete Account?',
    delete_account_dialog_message: 'Your account will be deleted and you will lose all your data!',
    email_not_verified: 'E-Mail is not verified!',
    email_verified: 'E-Mail is verified',
    send_verification_email: 'Send verification E-Mail',
    send_verification_email_again: 'Send verification E-Mail again',
    tasks: 'Tasks',
    create_task: 'Create task',
    edit_task: 'Edit task',
    users: 'Users',
    edit: 'Edit',
    online: 'Online',
    offline: 'Offline',
    no_connection_warning: 'No connection!',
    title_label: 'Title',
    title_hint: 'Enter title',
    no_connection: 'No connection',
    delete_task_title: 'Delete task?',
    delete_task_message: 'The task will be deleted!',
    error: 'Error!',
    properties: 'Properties',
    create_property: 'Create property',
    edit_property: 'Edit property',
    delete_property_title: 'Delete property?',
    delete_property_message: 'The property will be deleted!',
    full_name_label: 'Full name',
    full_name_hint: 'Enter full name',
    vat_label: 'UID',
    vat_hint: 'Enter UID',
    description_label: 'Description',
    description_hint: 'Enter description',
    name_label: 'Name',
    name_hint: 'Enter name',
    public_chats: 'Public chat',
    delete_message_title: 'Delete message?',
    delete_message_message: 'Message will be deleted!',
    users_count_title: '{number} Users',
    user_registrationg_graph_label: 'User registrations',
    required: 'Required',
    facebook: 'Facebook',
    github: 'Github',
    twitter: 'Twitter',
    phone: 'Phone',
    google: 'Google',
    facebook_color: '#303F9F',
    github_color: '#263238',
    twitter_color: '#36A2EB',
    phone_color: '#90A4AE',
    google_color: '#EA4335',
    password_color: '#4CAF50',
    chats: 'Chats',
    write_message_hint: 'Write message...',
    load_more_label: 'More...',
    my_location: 'My Location',
    select_user: 'Select user',
    operator_like_label: 'like',
    operator_notlike_label: 'not like',
    operator_equal_label: 'equal',
    operator_notequal_label: 'not equal',
    operator_novalue_label: 'no value',
    administration: 'Administration',
    roles: 'Roles',
    grants: 'Grants',
    private: 'Private',
    public: 'Public',
    grant_read_companies: 'Read companies',
    grant_create_company: 'Create company',
    grant_edit_company: 'Edit company',
    grant_delete_company: 'Delete company',
    is_admin_label: 'Administrator',
    predefined_messages: 'Predefined messages',
    delete_predefined_chat_message_title: 'Delete predefined message?',
    delete_predefined_chat_message_message: 'Predefined message will be deleted!',
    select_field: 'Select field',
    sorting: 'Sorting',
    filters: 'Filters',
    filter: 'Filter',
    add_filter: 'Add filter',
    delete_filter: 'Delete filter',
    change_sort_orientation: 'Change orientation',
    enable_case_sensitivity: 'Case sensitivity',
    hint_autocomplete: 'Select',
    enter_query_text: 'Enter text',
    email_label: 'Email',
    close_filter: 'Close filter',
    open_filter: 'Open filter',
    select_operator: 'Select operator',
    not_match_found: 'No match found',
    edit_user: 'Edit user',
    firestore: 'Firestore',
    hot_dog_status: 'Hot dog status',
    user_label_search: 'Search user',
    document: 'Document',
    collection: 'Collection',
    infinitelist: 'Infinitelist',
    error_required_field: 'Required Field',
    invalid_video_url: 'Only Vimeo URLs are supported',
    grant_read_delete_properties: 'Grant read/delete on property',
    operator_contains_label: 'Operator contains label',
    night_mode: 'Night mode',
    day_mode: 'Day mode',
    companies: 'Companies',
    create_company: 'Create company',
    edit_company: 'Edit company',
    delete_company_title: 'Delete company?',
    delete_company_message: 'The company will be deleted!',
    mark_chat_as_unread: 'Mark as unread',
    delete_chat: 'Delete chat',
    search: 'Search',
    update_available: 'A new version of this app is available.',
    load_update: 'Refresh',
    enable_notifications_message: 'Enable notifications?',
    enable: 'Enable',
    no_thanks: 'No, thanks',
    creation_time: 'Creation time',
    default: 'Default',
    red: 'Red',
    green: 'Green',
    notifications: 'Notifications',
    disable_notifications_dialog_title: 'Disable all notifications',
    disable_notifications_dialog_message: 'All notifications on all of your devices will be disabled!',
    update_title: 'Update available!',
    update_message: 'Click here to get the new version.',
    install: 'Install',
    disable: 'Disable',
    email_notifications: 'Email Notifications',
    activate: 'Activate',
    deactivate: 'Deactivate',
});

export default messages;
