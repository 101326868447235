import { Grid } from '@mui/material';
import React from 'react';
import { Card, Button } from '..';

function DeleteAccountPanel(props) {
    const { onClick } = props;

    return (
        <Card isHighlighted>
            <Grid container alignItems="center" spacing={2} style={{ justifyContent: 'space-between' }}>
                <Grid item>
                    <Card.Title>Delete Account</Card.Title>
                </Grid>
                <Grid item>
                    <Button onClick={onClick}>delete</Button>
                </Grid>
            </Grid>
        </Card>
    );
}

export default DeleteAccountPanel;
