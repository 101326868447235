import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import * as types from './types';
import { NTF_CATEGORIES } from '../../../constants/ntfCategories';

const initialState = {};

export const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_NOTIFICATIONS: {
            const { notifications, status, error, query } = action.payload;
            const { category } = query;
            const { notification: stateNotifications = [] } = state[category] || {};
            const newNotifications = getNewList(query, stateNotifications, notifications);
            const indexes = getListIndexes(newNotifications);
            return {
                ...state,
                [category]: {
                    notifications: newNotifications,
                    status,
                    error,
                    indexes,
                    noResults: !newNotifications.length,
                },
            };
        }
        case types.REFRESH_NOTIFICATIONS: {
            const { changes } = action.payload;
            const newState = {};

            [NTF_CATEGORIES.ENGAGEMENT, NTF_CATEGORIES.GENERAL, NTF_CATEGORIES.CONTENT].forEach((category) => {
                newState[category] = {
                    ...state[category],
                    notifications: handleListRefresh(
                        (state[category] || {}).notifications,
                        (state[category] || {}).indexes,
                        changes,
                    ),
                };
            });

            return { ...state, ...newState };
        }
        case types.GET_NOTIFICATIONS_OPTIONS:
            return { ...state, ...action.payload };
        case types.SET_NOTIFICATIONS_REQUEST_STATUS: {
            const { category } = action.payload;
            return {
                ...state,
                [category]: { ...(state[category] || {}), ...action.payload },
            };
        }
        case types.RESET_NOTIFICATIONS:
            return { ...initialState };
        default:
            return state;
    }
};
