import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EyeClosed = (props) => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="25" height="25" {...props}>
            <g id="Layer">
                <path
                    className="shp0"
                    d="M12.5 16.38C17.02 16.38 20.69 12.5 20.69 12.5C20.69 12.5 17.02 8.62 12.5 8.62C7.98 8.62 4.31 12.5 4.31 12.5C4.31 12.5 7.98 16.38 12.5 16.38Z"
                    fill="none"
                    stroke={props.htmlColor ? props.htmlColor : '#fff'}
                />
                <path
                    className="shp0"
                    d="M12.5 15.09C13.93 15.09 15.09 13.93 15.09 12.5C15.09 11.07 13.93 9.91 12.5 9.91C11.07 9.91 9.91 11.07 9.91 12.5C9.91 13.93 11.07 15.09 12.5 15.09Z"
                    fill="none"
                    stroke={props.htmlColor ? props.htmlColor : '#fff'}
                />
                <path
                    className="shp0"
                    d="M5.6 19.83L20.26 5.17"
                    fill="none"
                    stroke={props.htmlColor ? props.htmlColor : '#fff'}
                />
            </g>
        </SvgIcon>
    );
};

export default EyeClosed;
