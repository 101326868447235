import { REQUEST_STATUSES } from '../../../constants/requestStatuses';

const initialState = {
    experience: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const currentExperiencesSelector = (experienceId) => (state) =>
    state.currentExperiences[experienceId] || initialState;
