import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LinkIcon = (props) => {
    const { htmlColor } = props;
    return (
        <SvgIcon width="22px" height="10px" viewBox="0 0 22 10" {...props}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g
                    id="Media-Library_add-content-modal-hover-state"
                    transform="translate(-645.000000, -595.000000)"
                    stroke={htmlColor}
                >
                    <g id="Group" transform="translate(599.000000, 405.000000)">
                        <g id="Group-5" transform="translate(47.000000, 191.000000)">
                            <path d="M9.33333 1H5C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9H9.33333" />
                            <path d="M12.6666 1H17C19.2091 1 21 2.79086 21 5C21 7.20914 19.2091 9 17 9H12.6666" />
                            <line x1="7.05562" y1="5.25" x2="14.9445" y2="5.25" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default LinkIcon;
