import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FileRedIcon = (props) => {
    return (
        <SvgIcon width="15px" height="15px" viewBox="0 0 15 15" {...props}>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Save/Red" transform="translate(-7.000000, -7.000000)" stroke="#8F0339">
                    <path
                        d="M18.8018514,7.5 L7.5,7.5 L7.5,21.5 L21.5,21.5 L21.5,10.036973 L18.8018514,7.5 Z"
                        id="Rectangle"
                    />
                    <rect id="Rectangle" x="9.5" y="15.5" width="10" height="6" />
                    <rect id="Rectangle" x="10.5" y="7.5" width="8" height="4" />
                    <path d="" id="Path-12" />
                </g>
            </g>
        </SvgIcon>
    );
};

export default FileRedIcon;
