import * as types from './types';

export default function paths(state = {}, action) {
    const { location } = action;

    switch (action.type) {
        case types.VALUE_CHANGED:
            return { ...state, [location]: action.payload };

        case types.DESTROY:
            return { ...state, [location]: null };

        default:
            return state;
    }
}
