import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    contact: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const currentContactReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CURRENT_CONTACT:
            return { ...state, ...action.payload };
        case types.REFRESH_CURRENT_CONTACT:
            return { ...state, ...action.payload };
        case types.SET_CURRENT_CONTACT_ACTIVITY:
            return { ...state, ...action.payload };
        case types.SET_CURRENT_CONTACT_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_CURRENT_CONTACT:
            return { ...initialState };
        default:
            return state;
    }
};
