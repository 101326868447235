import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    savedExperienceURL: '',
    clickedNewExperience: false,
    clickedNewIcon: false,
    experienceCollectionPath: '',
    experienceCollectionName: '',
    tabStatus: false,
    dashboardStatus: true,
    selectedProperty: '',
    propertyCollectionPath: '',
    notificationStatus: false,
};

export const MainHeaderDataSlice = createSlice({
    name: 'mainHeaderData',
    initialState,
    reducers: {
        setDashboard: (state, { payload }) => {
            const { dashboardStatus } = payload;
            state.dashboardStatus = dashboardStatus;
        },
        setNotification: (state, { payload }) => {
            const { notificationStatus } = payload;
            state.notificationStatus = notificationStatus;
        },
        changeTabStatus: (state, { payload }) => {
            const { tab } = payload;
            state.tabStatus = tab;
        },
    },
});

export const { handleTab, setDashboard, setNotification, changeTabStatus } = MainHeaderDataSlice.actions;

export default MainHeaderDataSlice.reducer;

export const mainHeaderDataSliceSelector = (state) => state.mainHeaderData || {};
