import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DeleteSmallIcon = (props) => {
    return (
        <SvgIcon width="9px" height="8px" viewBox="0 0 9 8" {...props}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeOpacity="0.5">
                <g id="Media-Library_url-upload" transform="translate(-1043.000000, -444.000000)" stroke="#000000">
                    <g id="Group" transform="translate(599.000000, 405.000000)">
                        <g id="Icon/Delete/Small/Grey" transform="translate(444.500000, 39.000000)">
                            <g id="Group-2">
                                <line x1="0" y1="-9.09494702e-13" x2="7.90225397" y2="7.90225397" id="Path-9" />
                                <line
                                    x1="0"
                                    y1="2.72848411e-12"
                                    x2="7.90225397"
                                    y2="7.90225397"
                                    id="Path-9"
                                    transform="translate(3.951127, 3.951127) rotate(-90.000000) translate(-3.951127, -3.951127) "
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default DeleteSmallIcon;
