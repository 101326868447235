const firebaseConfig = {
    firebase_config_local: {
        apiKey: 'AIzaSyBLZJ_LbzFDr87t291Ms3pTidSkSkN-FC0',
        authDomain: 'true-tour-dev.firebaseapp.com',
        databaseURL: 'http://localhost:9001?ns=true-tour-dev',
        projectId: 'true-tour-dev',
        storageBucket: 'dev-media.truetour.app',
        messagingSenderId: '1055830118634',
        appId: '1:1055830118634:web:4c6547cff243424b00c793',
        measurementId: 'G-CCBPJG7WTJ',
        dynamicLinkDomain: 'dshare',
    },
    firebase_config_qa: {
        apiKey: 'AIzaSyBw7r9aDA5eSJCIhPAGW15HcRfHdvWXqVI',
        authDomain: 'vmttx-qa.firebaseapp.com',
        databaseURL: 'https://vmttx-qa.firebaseio.com',
        projectId: 'vmttx-qa',
        storageBucket: 'qa-media.truetour.app',
        messagingSenderId: '371791930957',
        appId: '1:371791930957:web:cb4c9f01c09a0f3f7f1e04',
        measurementId: 'G-1XY8EQ5325',
        dynamicLinkDomain: 'qshare',
    },
    firebase_config_dev: {
        apiKey: 'AIzaSyBLZJ_LbzFDr87t291Ms3pTidSkSkN-FC0',
        authDomain: 'true-tour-dev.firebaseapp.com',
        databaseURL: 'https://true-tour-dev.firebaseio.com',
        projectId: 'true-tour-dev',
        storageBucket: 'dev-media.truetour.app',
        messagingSenderId: '1055830118634',
        appId: '1:1055830118634:web:4c6547cff243424b00c793',
        measurementId: 'G-CCBPJG7WTJ',
        dynamicLinkDomain: 'dshare',
    },
    firebase_config_dev_2: {
        apiKey: 'AIzaSyCnzzQHuR46kcOF9NI4WqLYfiI27LkLHgo',
        authDomain: 'true-tour-dev-2.firebaseapp.com',
        databaseURL: 'https://true-tour-dev-2-default-rtdb.firebaseio.com',
        projectId: 'true-tour-dev-2',
        storageBucket: 'dev2-media.truetour.app',
        messagingSenderId: '1002112171616',
        appId: '1:1002112171616:web:351d152e820357d5e88d05',
        measurementId: 'G-8MDY0XJJ85',
        dynamicLinkDomain: 'd2share',
    },
    firebase_config_staging: {
        apiKey: 'AIzaSyDw9IsFOo4OGWmt0RPDTMoY_8eKeJbdAZA',
        authDomain: 'ttx-stage.firebaseapp.com',
        projectId: 'ttx-stage',
        storageBucket: 'stage-media.truetour.app',
        messagingSenderId: '955062908783',
        appId: '1:955062908783:web:2d6adb989b4906c1725a9a',
        measurementId: 'G-9V5GBBS22J',
        dynamicLinkDomain: 'sshare',
    },
    firebase_config_prod: {
        apiKey: 'AIzaSyCsyPb6ZfAcFm_SgPUZJOkQCTJLHVbqyzU',
        authDomain: 'true-tour.firebaseapp.com',
        databaseURL: 'https://true-tour.firebaseio.com',
        projectId: 'true-tour',
        storageBucket: 'production-media.truetour.app',
        messagingSenderId: '198610373357',
        appId: '1:198610373357:web:c8b2b7ff7156f61be85ae4',
        measurementId: 'G-C2ZR6S68CQ',
        dynamicLinkDomain: 'share',
    },
    firebase_config_demo: {
        apiKey: 'AIzaSyCNURjHHZPD9XBogzUhNwsR1uMEtV9Llvc',
        authDomain: 'vmttx-demo.firebaseapp.com',
        databaseURL: 'https://vmttx-demo-default-rtdb.firebaseio.com',
        projectId: 'vmttx-demo',
        storageBucket: 'demo-media.appspot.com',
        messagingSenderId: '481994659802',
        appId: '1:481994659802:web:b30b7c3bce6d308f0b52e3',
        measurementId: 'G-HFCKE9CBXS',
        dynamicLinkDomain: 'demoshare',
    },
};

export default firebaseConfig;
