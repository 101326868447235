import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Revert = (props) => {
    return (
        <SvgIcon width="17" height="14" viewBox="0 0 17 14" fill="none" {...props}>
            <path
                d="M4.0396 8.34653C3.33504 5.07299 5.41241 1.84495 8.67815 1.13806C11.9439 0.431168 15.1612 2.51847 15.8628 5.79202C16.5643 9.06556 14.4899 12.2966 11.2242 13.0005"
                stroke="white"
                strokeMiterlimit="10"
                fill="none"
            />
            <path d="M6.80645 5.32031L3.90178 8.68031L1 5.32031" stroke="white" strokeMiterlimit="10" fill="none" />
        </SvgIcon>
    );
};

export default Revert;
