import { REQUEST_STATUSES } from '../../../constants/requestStatuses';

const initialState = {
    experiences: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
    noResults: false,
};

export const experiencesSelector = (propertyId, experienceTab) => (state) => ({
    ...initialState,
    ...(state.experiences[`${propertyId}#${experienceTab}`] || {}),
});
