import React from 'react';
import { Checkbox as MUICheckbox } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
    checkboxChecked: {
        '& + span': {
            color: '#97023b',
        },
    },
});

function Checkbox(props) {
    const { classes, values, name, onChange } = props;

    return (
        <MUICheckbox
            color="primary"
            checked={!!values[name]}
            name={name}
            classes={{ checked: classes.checkboxChecked }}
            onChange={onChange}
        />
    );
}

export default withStyles(styles)(Checkbox);
