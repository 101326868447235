import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ElementAdd = (props) => {
    const { htmlColor } = props;
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <rect
                x="2"
                y="4"
                width="20"
                height="16"
                stroke={htmlColor}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="2 3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 8C12.3452 8 12.625 8.29848 12.625 8.66667L12.625 15.3333C12.625 15.7015 12.3452 16 12 16C11.6548 16 11.375 15.7015 11.375 15.3333L11.375 8.66667C11.375 8.29848 11.6548 8 12 8Z"
                fill={htmlColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.75 12C15.75 12.3681 15.4702 12.6666 15.125 12.6666L8.875 12.6666C8.52982 12.6666 8.25 12.3681 8.25 12C8.25 11.6318 8.52982 11.3333 8.875 11.3333L15.125 11.3333C15.4702 11.3333 15.75 11.6318 15.75 12Z"
                fill={htmlColor}
            />
        </SvgIcon>
    );
};

export default ElementAdd;
