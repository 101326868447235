import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CustomizeIcon = (props) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.28198 2.56373C6.91323 1.93862 8.38759 0.681848 10.449 1.07454C9.38316 1.69533 7.49778 3.30157 8.48269 4.76023L5.95768 7.26067L3.75696 5.06417L6.28198 2.56373Z"
                strokeLinejoin="round"
                stroke={props.htmlColor || '#fff'}
                fill="none"
            />
            <path
                d="M7.22217 9.22949L8.80031 7.66672L15.7886 14.6409C16.2227 15.0742 16.2214 15.7753 15.7856 16.2068V16.2068C15.3498 16.6384 14.6446 16.637 14.2105 16.2038L7.22217 9.22949Z"
                strokeLinejoin="round"
                stroke={props.htmlColor || '#fff'}
                fill="none"
            />
            <path
                d="M6.51257 11.6201L8.08761 13.186L5.03826 16.2175C4.60332 16.6499 3.89815 16.6499 3.46322 16.2175V16.2175C3.02828 15.7851 3.02828 15.0841 3.46322 14.6517L6.51257 11.6201Z"
                stroke={props.htmlColor || '#fff'}
                strokeLinejoin="round"
                fill="none"
            />
            <rect
                width="1.77679"
                height="3.10931"
                transform="matrix(0.710557 -0.70364 0.707784 0.706429 1 7.79785)"
                stroke={props.htmlColor || '#fff'}
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M14.6081 4.24373L16.2364 3.01085L16.8091 3.58246L15.5626 5.19641L14.6081 4.24373Z"
                stroke={props.htmlColor || '#fff'}
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M11.7036 8.09536L15.0886 4.7168"
                stroke={props.htmlColor || '#fff'}
                strokeLinecap="round"
                fill="none"
            />
        </SvgIcon>
    );
};

export default CustomizeIcon;
