import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {};

const elementInitialState = {
    elements: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const elementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ELEMENTS: {
            const { propertyId, experienceId, elements, status, error, query } = action.payload;
            const { elements: stateElements = [] } = state[`${propertyId}${experienceId}`] || {};
            const newElements = getNewList(query, stateElements, elements);
            const indexes = getListIndexes(newElements);
            return {
                ...state,
                [`${propertyId}${experienceId}`]: {
                    elements: newElements,
                    status,
                    error,
                    indexes,
                },
            };
        }
        case types.REFRESH_ELEMENTS: {
            const { propertyId, experienceId, elementId, element } = action.payload;
            const { elements = [], indexes = {} } = state[`${propertyId}${experienceId}`] || {};

            if (indexes.hasOwnProperty(elementId)) {
                const newElements = [...elements];

                newElements[indexes[elementId]] = element;

                return {
                    ...state,
                    [`${propertyId}${experienceId}`]: {
                        ...(state[`${propertyId}${experienceId}`] || {}),
                        elements: newElements,
                    },
                };
            }

            return state;
        }
        case types.ADD_ELEMENT: {
            const { propertyId, experienceId, element } = action.payload;
            const { elements = [] } = state[`${propertyId}${experienceId}`] || {};
            const newElements = [...elements];

            newElements.push(element);

            const indexes = getListIndexes(newElements);

            return {
                ...state,
                [`${propertyId}${experienceId}`]: {
                    ...(state[`${propertyId}${experienceId}`] || {}),
                    elements: newElements,
                    indexes,
                },
            };
        }
        case types.SET_ELEMENTS_REQUEST_STATUS: {
            const { propertyId, experienceId, status } = action.payload;
            return {
                ...state,
                [`${propertyId}${experienceId}`]: {
                    ...elementInitialState,
                    ...(state[`${propertyId}${experienceId}`] || {}),
                    status,
                },
            };
        }
        case types.RESET_ELEMENTS: {
            const { propertyId, experienceId, elementId } = action.payload;

            if (propertyId && experienceId && elementId) {
                const { elements: stateElements = [], indexes: stateIndexes = {} } =
                    state[`${propertyId}${experienceId}`] || {};
                const newElements = [...stateElements];

                if (stateIndexes.hasOwnProperty(elementId)) {
                    newElements.splice(stateIndexes[elementId], 1);
                }
                const indexes = getListIndexes(newElements);
                return {
                    ...state,
                    [`${propertyId}${experienceId}`]: {
                        ...(state[`${propertyId}${experienceId}`] || {}),
                        elements: newElements,
                        indexes,
                    },
                };
            }

            if (propertyId && experienceId) {
                return { ...state, [`${propertyId}${experienceId}`]: elementInitialState };
            }

            return state;
        }
        default:
            return state;
    }
};
