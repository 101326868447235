import { REQUEST_STATUSES } from '../../../constants/requestStatuses';

const initialState = {
    elements: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const contentElementsSelector = (mediaLibId, mediaItemId) => (state) => {
    const elements = state.contentElements[`${mediaLibId}${mediaItemId}`] || {};

    return { ...initialState, ...elements };
};
