import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    globalTabs: {},
    noTabsOpen: false,
};

export const GlobalTabsDataSlice = createSlice({
    name: 'globalTabsData',
    initialState,
    reducers: {
        handleTab: (state, { payload }) => {
            const { userId, tabId, newState } = payload;
            if (!state.globalTabs[userId] && tabId !== '') {
                state.globalTabs[userId] = {};
                state.globalTabs[userId][tabId] = newState;
            }
            if (newState.status === 'active') {
                const tabIdBaseSplit = tabId?.split('/') || [];
                const propertyId = tabIdBaseSplit[2];
                const experienceId = tabIdBaseSplit[4];

                const approxTabId = Object.keys(state.globalTabs[userId]).find((key) => {
                    const keyTabIdBaseSplit = key?.split('/') || [];
                    const keyPropertyId = keyTabIdBaseSplit[2];
                    const keyExperienceId = keyTabIdBaseSplit[4];
                    return keyPropertyId === propertyId && keyExperienceId === experienceId;
                });

                if (tabId && approxTabId && tabId !== approxTabId) {
                    const oldObj = state.globalTabs[userId];
                    const keys = Object.keys(state.globalTabs[userId]);
                    const objWithRenamedKey = keys.reduce((acc, val) => {
                        if (val === approxTabId) {
                            acc[tabId] = oldObj[approxTabId];
                        } else {
                            acc[val] = oldObj[val];
                        }
                        return acc;
                    }, {});
                    state.globalTabs[userId] = objWithRenamedKey;
                }

                state.noTabsOpen = false;
                const openAndInactive = Object.keys(state.globalTabs[userId]).filter(
                    (tab) => state.globalTabs[userId][tab].status !== 'closed',
                );

                openAndInactive.forEach((tab) => {
                    state.globalTabs[userId][tab].status = 'inactive';
                });

                state.globalTabs[userId][tabId] = newState;

                state.globalTabs[userId][tabId].status = 'active';
            } else if (newState.status === 'closed') {
                state.globalTabs[userId][tabId] = newState;
                const openAndInactive = Object.keys(state.globalTabs[userId]).filter(
                    (tab) => state.globalTabs[userId][tab].status !== 'closed',
                );
                if (openAndInactive.length > 0) {
                    openAndInactive.forEach((tab) => {
                        state.globalTabs[userId][tab].status = 'inactive';
                    });
                    const lastItem = openAndInactive.slice(-1);
                    if (!newState?.dashboardStatus) {
                        state.globalTabs[userId][lastItem].status = 'active';
                    }
                } else {
                    state.noTabsOpen = true;
                    state.globalTabs[userId] = {};
                }
            } else if (newState.status === 'deactivate' && state.globalTabs[userId]) {
                const openAndInactive = Object.keys(state.globalTabs[userId]).filter(
                    (tab) => state.globalTabs[userId][tab].status !== 'closed',
                );
                openAndInactive.forEach((tab) => {
                    state.globalTabs[userId][tab].status = 'inactive';
                });
            }
        },
        closeAllTabs: (state, { payload }) => {
            const { userId, action } = payload;
            if (action === 'closeAll') {
                state.globalTabs[userId] = {};
            }
        },
    },
});

export const { handleTab, closeAllTabs } = GlobalTabsDataSlice.actions;

export default GlobalTabsDataSlice.reducer;
