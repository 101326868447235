import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FeatureIcon = (props) => {
    return (
        <SvgIcon
            width="184"
            height="197"
            viewBox="0 0 184 197"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M84.7056 74.2582C87.2043 71.7838 93.0403 66.8091 101.2 68.3635C96.9811 70.8208 89.5181 77.1788 93.4168 82.9527L83.4219 92.8503L74.7107 84.1558L84.7056 74.2582Z"
                stroke="#8F0339"
                strokeLinejoin="round"
            />
            <path
                d="M88.4275 100.643L94.6742 94.4573L123.678 123.403C124.655 124.378 124.652 125.956 123.671 126.928L120.977 129.596C119.996 130.567 118.409 130.564 117.432 129.589L88.4275 100.643Z"
                stroke="#8F0339"
                strokeLinejoin="round"
            />
            <path
                d="M85.6185 110.105L91.853 116.303L78.4383 129.639C77.4591 130.613 75.8716 130.613 74.8924 129.639L72.2038 126.966C71.2246 125.993 71.2246 124.415 72.2038 123.441L85.6185 110.105Z"
                stroke="#8F0339"
                strokeLinejoin="round"
            />
            <rect
                width="7.03312"
                height="12.3077"
                transform="matrix(0.710557 -0.70364 0.707784 0.706429 63.7978 94.977)"
                stroke="#8F0339"
                strokeLinejoin="round"
            />
            <path
                d="M117.663 80.9096L124.109 76.0295L126.376 78.2921L121.442 84.6806L117.663 80.9096Z"
                stroke="#8F0339"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M106.166 96.1555L119.566 82.782" stroke="#8F0339" strokeLinecap="round" />
            <path d="M95.9044 32.6582L95.9044 1" stroke="#8F0339" strokeLinecap="round" strokeDasharray="6 6" />
            <path d="M95.9019 196.046L95.9044 161.55" stroke="#8F0339" strokeLinecap="round" />
            <path d="M52.3721 176.168L65.515 153.408" stroke="#8F0339" strokeLinecap="round" strokeDasharray="6 6" />
            <path d="M163.253 100.773L182.964 100.773" stroke="#8F0339" strokeLinecap="round" strokeDasharray="4 4" />
            <path d="M22.6948 99.9319H34.5666" stroke="#8F0339" strokeLinecap="round" />
            <path d="M16.6225 64.7972L40.5557 75.6589" stroke="#8F0339" strokeLinecap="round" strokeDasharray="4 6" />
            <path d="M142.341 77.1113L182.144 56.8307" stroke="#8F0339" strokeLinecap="round" />
            <path d="M142.341 77.1113L182.144 56.8307" stroke="#8F0339" strokeLinecap="round" strokeDasharray="4 4" />
            <path d="M9.66467 144.713L34.5666 131.59" stroke="#8F0339" strokeLinecap="round" />
            <path d="M136.771 154.532L155.74 176.849" stroke="#8F0339" strokeLinecap="round" />
            <path d="M36.0678 24.6949L63.6846 59.8077" stroke="#8F0339" strokeLinecap="round" />
            <path d="M36.0678 24.6949L63.6846 59.8077" stroke="#8F0339" strokeLinecap="round" />
            <path d="M142.341 124.433L182.144 144.713" stroke="#8F0339" strokeLinecap="round" />
            <path d="M137.456 40.5728V24.7437" stroke="#8F0339" strokeLinecap="round" />
            <path d="M129.541 32.6582L145.37 32.6582" stroke="#8F0339" strokeLinecap="round" />
            <path d="M0.999996 100.992L10.8932 100.992" stroke="#8F0339" strokeLinecap="round" />
            <path d="M5.94659 96.0449L5.94659 105.938" stroke="#8F0339" strokeLinecap="round" />
            <path d="M119.067 175.543L128.96 175.513" stroke="#8F0339" strokeLinecap="round" />
            <path d="M123.998 170.581L124.028 180.475" stroke="#8F0339" strokeLinecap="round" />
        </SvgIcon>
    );
};
export default FeatureIcon;
