import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ElementCircle = (props) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fill="none"
                d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke="white"
            />
        </SvgIcon>
    );
};

export default ElementCircle;
