import React from 'react';
import { Accordion as MUIAccordion, AccordionSummary, Typography, AccordionDetails, Tooltip } from '@mui/material';
import { uniqueId, kebabCase } from 'lodash';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { HelpIcon } from '../../../../../../../icons';

const styles = () => ({
    accordionInner: {
        padding: 0,
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        '&::before': {
            display: 'block !important',
        },
        '&:first-child::before': {
            display: 'none !important',
        },
    },
    accordionInnerExpanded: {
        margin: '0 !important',
        '&::before': {
            opacity: '1 !important',
        },
    },
    accordionSummary: {
        padding: 0,
        minHeight: '100% !important',
    },
    accordionInnerContent: {
        margin: '0 !important',
        padding: '15px 0',
    },
    accordionExpandIconWrapper: {
        padding: 0,
        margin: 0,
    },
    accordionInnerDetails: {
        padding: '0 0 9px 24px',
        display: 'block',
    },
    accordionInnerSummaryTypography: {
        fontSize: '14px',
        color: '#000',
        display: 'flex',
        alignItems: 'center',
    },
    accordionInnerSummaryIcon: {
        display: 'inline-block',
        lineHeight: 0,
        fontSize: '18px',
    },
    accordionInnerSummaryText: {
        display: 'inline-block',
        marginLeft: '0.5em',
        marginRight: '0.5em',
    },
    tooltip: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        color: 'white',
        fontSize: '12px',
        lineHeight: '16px',
        borderRadius: '2px',
    },
    tooltipArrow: {
        color: 'rgba(0,0,0,0.8)',
    },
});

function Accordion(props) {
    const { classes, icon, label, disclosure, children } = props;
    const normalizedLabel = label.replace(/\s/g, '');
    const id = uniqueId(normalizedLabel);
    const kebabLabel = kebabCase(label) + '-content';

    return (
        <MUIAccordion
            square
            defaultExpanded
            classes={{
                root: classes.accordionInner,
                expanded: classes.accordionInnerExpanded,
            }}
        >
            <AccordionSummary
                classes={{
                    root: classes.accordionSummary,
                    content: classes.accordionInnerContent,
                    expandIcon: classes.accordionExpandIconWrapper,
                }}
                expandIcon={<ExpandMoreIcon />}
                id={id}
                aria-controls={kebabLabel}
            >
                <Typography className={classes.accordionInnerSummaryTypography}>
                    <span className={classes.accordionInnerSummaryIcon}>{icon}</span>
                    <span className={classes.accordionInnerSummaryText}>{label}</span>
                    <Tooltip
                        arrow
                        placement="top"
                        title={disclosure}
                        classes={{
                            tooltip: classes.tooltip,
                            arrow: classes.tooltipArrow,
                        }}
                    >
                        <span className={classes.accordionInnerSummaryIcon}>
                            <HelpIcon htmlColor="#8f0339" style={{ fontSize: '18px' }} />
                        </span>
                    </Tooltip>
                </Typography>
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.accordionInnerDetails }}>{children}</AccordionDetails>
        </MUIAccordion>
    );
}

export default withStyles(styles)(Accordion);
