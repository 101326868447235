import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    experience: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
    noResults: false,
};

export const currentSharedExperienceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CURRENT_SHARED_EXPERIENCE:
            return { ...state, ...action.payload };
        case types.REFRESH_CURRENT_SHARED_EXPERIENCE:
            return { ...state, ...action.payload };
        case types.SET_CURRENT_SHARED_EXPERIENCE_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_CURRENT_SHARED_EXPERIENCE:
            return { ...initialState };
        default:
            return state;
    }
};
