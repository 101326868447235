import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AddGrey = (props) => {
    return (
        <SvgIcon fontSize="small" width={15} height={15} viewBox="0 0 15 15" {...props}>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeOpacity="0.5">
                <g id="Icon/Add/Small/Grey" stroke="#000000">
                    <line
                        x1="7.5"
                        y1="0"
                        x2="7.5"
                        y2="15"
                        id="Line-2"
                        transform="translate(7.500000, 7.500000) rotate(-270.000000) translate(-7.500000, -7.500000) "
                    />
                    <line
                        x1="7.5"
                        y1="0"
                        x2="7.5"
                        y2="15"
                        id="Line-2"
                        transform="translate(7.500000, 7.500000) rotate(-360.000000) translate(-7.500000, -7.500000) "
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

export default AddGrey;
