import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    contacts: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const contactsReducer = (state = {}, action) => {
    switch (action.type) {
        case types.GET_CONTACTS: {
            const { propertyId, contacts, status, error, query } = action.payload;
            const newContacts = getNewList(query, state[propertyId].contacts, contacts);
            const indexes = getListIndexes(newContacts);

            return {
                ...state,
                [propertyId]: {
                    contacts: newContacts,
                    status,
                    error,
                    indexes,
                    noResults: !newContacts.length,
                },
            };
        }
        case types.REFRESH_CONTACTS: {
            const { propertyId, changes } = action.payload;
            const newContacts = handleListRefresh(
                (state[propertyId] || {}).contacts,
                (state[propertyId] || {}).indexes,
                changes,
            );

            return {
                ...state,
                [propertyId]: { ...(state[propertyId] || {}), contacts: newContacts },
            };
        }
        case types.SET_CONTACTS_REQUEST_STATUS: {
            const { propertyId, status } = action.payload;
            return {
                ...state,
                [propertyId]: { ...(state[propertyId] || {}), status },
            };
        }
        case types.RESET_CONTACTS: {
            const { propertyId } = action.payload;
            if (propertyId) {
                return { ...state, [propertyId]: initialState };
            }

            return {};
        }
        default:
            return state;
    }
};
