import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HumBurgurMenu = (props) => {
    return (
        <SvgIcon width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <line
                y1="-0.5"
                x2="6.00008"
                y2="-0.5"
                transform="matrix(0.999992 -0.00404384 0.00572932 0.999984 1 1.02441)"
                stroke={props.htmlColor || '#fff'}
            />
            <line
                y1="-0.5"
                x2="6.00008"
                y2="-0.5"
                transform="matrix(0.999992 -0.00404388 0.00572926 0.999984 1 3)"
                stroke={props.htmlColor || '#fff'}
            />
            <line
                y1="-0.5"
                x2="6.00008"
                y2="-0.5"
                transform="matrix(0.999992 -0.00404382 0.00572935 0.999984 1 5)"
                stroke={props.htmlColor || '#fff'}
            />
        </SvgIcon>
    );
};

export default HumBurgurMenu;
