import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MobilePhoneIcon = (props) => {
    const { style, htmlColor, ...rest } = props;

    return (
        <SvgIcon
            width="17"
            height="32"
            viewBox="0 0 17 32"
            xmlns="http://www.w3.org/2000/svg"
            fill={htmlColor}
            style={{
                height: '1em',
                width: '0.529296875em',
                fontSize: '1em',
                ...style,
            }}
            {...rest}
        >
            <path d="M14.4 0h-11.859c-1.403 0-2.541 1.264-2.541 2.824v26.353c0 1.559 1.138 2.824 2.541 2.824h11.859c1.403 0 2.541-1.264 2.541-2.824v-26.353c0-1.559-1.138-2.824-2.541-2.824zM2.541 1.882h11.859c0.468 0 0.847 0.421 0.847 0.941v26.353c0 0.52-0.379 0.941-0.847 0.941h-11.859c-0.468 0-0.847-0.421-0.847-0.941v-26.353c0-0.52 0.379-0.941 0.847-0.941z" />
            <path d="M8.471 22.588c-1.559 0-2.824 1.264-2.824 2.824s1.264 2.824 2.824 2.824c1.559 0 2.824-1.264 2.824-2.824s-1.264-2.824-2.824-2.824zM8.471 24.249c0.642 0 1.163 0.521 1.163 1.163s-0.521 1.163-1.163 1.163c-0.642 0-1.163-0.521-1.163-1.163s0.521-1.163 1.163-1.163z" />
            <path d="M15.959 26.353c0.542 0 0.982 0.421 0.982 0.941 0 0.462-0.347 0.846-0.806 0.926l-0.177 0.015h-3.683c-0.542 0-0.982-0.421-0.982-0.941 0-0.462 0.347-0.846 0.806-0.926l0.177-0.015h3.683z" />
            <path d="M4.665 26.353c0.542 0 0.982 0.421 0.982 0.941 0 0.462-0.347 0.846-0.806 0.926l-0.177 0.015h-3.683c-0.542 0-0.982-0.421-0.982-0.941 0-0.462 0.347-0.846 0.806-0.926l0.177-0.015h3.683z" />
        </SvgIcon>
    );
};

export default MobilePhoneIcon;
