import getMenuItems from './menuItems';
import { VMIcon } from '../icons';
import locales from './locales';
import routes from './routes';
import { themes } from './themes';
import grants from './grants';
import firebaseConfig from './firebaseConfig';

const config = {
    ...firebaseConfig,
    firebase_providers: ['google.com'],
    onAuthStateChanged: undefined,
    initial_state: {
        drawer: {
            open: true,
            mobileOpen: false,
            useMinified: true,
        },
        themeSource: {
            isNightModeOn: false,
            source: 'default',
        },
        locale: 'en',
        filters: {
            properties: {
                sortField: 'last-updated',
                sortOrientation: false,
            },
        },
    },
    drawer_width: 256,
    appIcon: VMIcon,
    locales,
    themes,
    grants,
    routes,
    getMenuItems,
    firebaseLoad: () => import('./firebase'),
};

export default config;
