import { withStyles } from '@mui/styles';
import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '../../Dashboard/pages/MyAccount/components';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    heading: {
        fontSize: 36,
        textAlign: 'center',
    },
});

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidCatch(error) {
        this.setState({ error });
    }

    handleRefreshError = () => {
        this.setState({ error: null });
    };

    render() {
        const { classes } = this.props;
        if (this.state.error) {
            return (
                <div className={classes.container}>
                    <div className={classes.box}>
                        <RefreshIcon
                            onClick={this.handleRefreshError}
                            color="primary"
                            sx={{ fontSize: 70, cursor: 'pointer' }}
                        />
                        <h2 className={classes.heading}>Please refresh!</h2>
                        <Button onClick={this.handleRefreshError}>Refresh</Button>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default withStyles(styles)(ErrorBoundary);
