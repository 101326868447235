import React from 'react';

const ElementSize = (props) => {
    const { size } = props;

    if (size === 'large') {
        return (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
                <path
                    d="M4.02832 19L4.02832 6.15039L6.75293 6.15039L6.75293 16.75L11.9648 16.75L11.9648 19L4.02832 19Z"
                    fill="white"
                />
                <path d="M22 10.5L19 13.5L16 10.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    if (size === 'medium' || !size) {
        return (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
                <path
                    d="M6.06738 19L2.97363 8.91895L2.89453 8.91895C2.90625 9.15918 2.92383 9.52246 2.94727 10.0088C2.97656 10.4893 3.00293 11.002 3.02637 11.5469C3.0498 12.0918 3.06152 12.584 3.06152 13.0234L3.06152 19L0.626953 19L0.626953 6.15039L4.33594 6.15039L7.37695 15.9766L7.42969 15.9766L10.6553 6.15039L14.3643 6.15039L14.3643 19L11.8242 19L11.8242 12.918C11.8242 12.5137 11.8301 12.0479 11.8418 11.5205C11.8594 10.9932 11.8799 10.4922 11.9033 10.0176C11.9268 9.53711 11.9443 9.17676 11.9561 8.93652L11.877 8.93652L8.56348 19L6.06738 19Z"
                    fill="white"
                />
                <path d="M25 10.5L22 13.5L19 10.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    if (size === 'small') {
        return (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
                <path
                    d="M11.7451 15.4316C11.7451 16.1934 11.5605 16.8555 11.1914 17.418C10.8223 17.9805 10.2832 18.4141 9.57422 18.7188C8.87109 19.0234 8.01562 19.1758 7.00781 19.1758C6.5625 19.1758 6.12598 19.1465 5.69824 19.0879C5.27637 19.0293 4.86914 18.9443 4.47656 18.833C4.08984 18.7158 3.7207 18.5723 3.36914 18.4023L3.36914 15.8711C3.97852 16.1406 4.61133 16.3838 5.26758 16.6006C5.92383 16.8174 6.57422 16.9258 7.21875 16.9258C7.66406 16.9258 8.02148 16.8672 8.29102 16.75C8.56641 16.6328 8.76562 16.4717 8.88867 16.2666C9.01172 16.0615 9.07324 15.8271 9.07324 15.5635C9.07324 15.2412 8.96484 14.9658 8.74805 14.7373C8.53125 14.5088 8.23242 14.2949 7.85156 14.0957C7.47656 13.8965 7.05176 13.6826 6.57715 13.4541C6.27832 13.3135 5.95312 13.1436 5.60156 12.9443C5.25 12.7393 4.91602 12.4902 4.59961 12.1973C4.2832 11.9043 4.02246 11.5498 3.81738 11.1338C3.61816 10.7119 3.51855 10.208 3.51855 9.62207C3.51855 8.85449 3.69434 8.19824 4.0459 7.65332C4.39746 7.1084 4.89844 6.69238 5.54883 6.40527C6.20508 6.1123 6.97852 5.96582 7.86914 5.96582C8.53711 5.96582 9.17285 6.04492 9.77637 6.20313C10.3857 6.35547 11.0215 6.57813 11.6836 6.87109L10.8047 8.98926C10.2129 8.74902 9.68262 8.56445 9.21387 8.43555C8.74512 8.30078 8.26758 8.2334 7.78125 8.2334C7.44141 8.2334 7.15137 8.28906 6.91113 8.40039C6.6709 8.50586 6.48926 8.6582 6.36621 8.85742C6.24316 9.05078 6.18164 9.27637 6.18164 9.53418C6.18164 9.83887 6.26953 10.0967 6.44531 10.3076C6.62695 10.5127 6.89648 10.7119 7.25391 10.9053C7.61719 11.0986 8.06836 11.3242 8.60742 11.582C9.26367 11.8926 9.82324 12.2178 10.2861 12.5576C10.7549 12.8916 11.1152 13.2871 11.3672 13.7441C11.6191 14.1953 11.7451 14.7578 11.7451 15.4316Z"
                    fill="white"
                />
                <path d="M21.5 10.5L18.5 13.5L15.5 10.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
    return '';
};

export default ElementSize;
