import React from 'react';
import { FormControlLabel as MUIFormControlLabel } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Email, Phone, Push } from './components';

const styles = () => ({
    accordionFormRoot: {
        display: 'flex',
    },
    accordionFormLabel: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
    },
});

function FormControlLabel(props) {
    const { classes, label, control } = props;

    return (
        <MUIFormControlLabel
            label={label}
            control={control}
            classes={{
                root: classes.accordionFormRoot,
                label: classes.accordionFormLabel,
            }}
        />
    );
}

FormControlLabel.Email = Email;
FormControlLabel.Phone = Phone;
FormControlLabel.Push = Push;

export default withStyles(styles)(FormControlLabel);
