import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    members: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const membersReducer = (state = {}, action) => {
    switch (action.type) {
        case types.GET_MEMBERS: {
            const { propertyId, members, status, error, query } = action.payload;
            const newMembers = getNewList(query, state[propertyId].members, members);
            const indexes = getListIndexes(newMembers);
            return {
                ...state,
                [propertyId]: {
                    members: newMembers,
                    status,
                    error,
                    indexes,
                    noResults: !newMembers.length,
                },
            };
        }
        case types.REFRESH_MEMBERS: {
            const { propertyId, changes } = action.payload;
            const newMembers = handleListRefresh(
                (state[propertyId] || {}).members,
                (state[propertyId] || {}).indexes,
                changes,
            );

            return {
                ...state,
                [propertyId]: { ...(state[propertyId] || {}), members: newMembers },
            };
        }
        case types.SET_MEMBERS_REQUEST_STATUS: {
            const { propertyId, status } = action.payload;
            return {
                ...state,
                [propertyId]: { ...(state[propertyId] || {}), status },
            };
        }
        case types.RESET_MEMBERS: {
            const { propertyId } = action.payload;
            if (propertyId) {
                return { ...state, [propertyId]: initialState };
            }

            return {};
        }
        default:
            return state;
    }
};
