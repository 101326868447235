import * as types from './types';

export const initialState = {
    isConnected: true,
};

export default function connection(state = initialState, { payload, type }) {
    if (type === types.ON_CONNECTION_STATE_CHANGED) {
        return { ...state, ...payload };
    }

    return state;
}
