import { REQUEST_STATUSES } from '../../../constants/requestStatuses';

const initialState = {
    contacts: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const contactsSelector = (propertyId) => (state) => ({
    ...initialState,
    ...(state.contacts[propertyId] || {}),
});
