import { compose } from 'redux';
import withStyles from '@mui/styles/withStyles';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

const styles = () => ({
    input: {
        padding: '0px',
    },
    root: {},
});
const FormTextField = (props) => {
    const {
        control,
        name,
        label,
        placeholder = '',
        size,
        variant = 'outlined',
        fullWidth = true,
        autoFocus = false,
        style = {},
        classes,
        onBlur,
        onChange,
        type = '',
        inputProps = {},
        margin = 'none',
        helperText,
        hidden,
        ...rest
    } = props;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const { error, invalid, isDirty, isTouched } = fieldState;
                return (
                    <TextField
                        name={field.name}
                        ref={field.ref}
                        classes={{ root: classes.root }}
                        className={classes?.input}
                        fullWidth={fullWidth}
                        autoFocus={autoFocus}
                        type={type}
                        label={label}
                        margin={margin}
                        size={size}
                        placeholder={placeholder}
                        variant={variant}
                        style={{
                            display: hidden && !field.value && `none`,
                            ...style,
                        }}
                        error={(isDirty || isTouched) && invalid}
                        helperText={((isDirty || isTouched) && invalid && error?.message) || helperText}
                        value={field.value}
                        onChange={(e) => {
                            if (onChange) {
                                onChange(e);
                            }
                            field.onChange(e);
                        }}
                        onBlur={(e) => {
                            if (onBlur) {
                                onBlur(e);
                            }
                            field.onBlur(e);
                        }}
                        inputProps={inputProps}
                        {...rest}
                    />
                );
            }}
        />
    );
};

export default compose(withStyles(styles, { withTheme: true }))(FormTextField);
