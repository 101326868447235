import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    members: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const currentMembersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CURRENT_MEMBERS: {
            const { members, status, error } = action.payload;
            const newMembers = {};
            members.forEach((member) => {
                newMembers[member.id] = member;
            });
            return { ...state, members: newMembers, status, error };
        }
        case types.REFRESH_CURRENT_MEMBERS: {
            const { changes } = action.payload;
            const { members } = state;
            const newMembers = { ...members };

            Object.keys(changes).forEach((memberId) => {
                const memberData = members[memberId];
                if (memberData) {
                    newMembers[memberId] = {
                        id: memberData.id,
                        name: memberData.name,
                        email: memberData.email,
                        photoThumbnails: memberData.photoThumbnails,
                        status: memberData.status,
                    };
                }
            });

            return { ...state, ...newMembers };
        }
        case types.SET_CURRENT_MEMBERS_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_CURRENT_MEMBERS: {
            return { ...initialState };
        }
        default:
            return state;
    }
};
