import { getListIndexes, getNewList } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    integrations: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const integrationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_INTEGRATIONS: {
            const { integrations, status, error, query } = action.payload;
            const newIntegrations = getNewList(query, state.integrations, integrations);
            const indexes = getListIndexes(newIntegrations);
            let noResults = false;
            if (newIntegrations.length === 0) noResults = true;
            return {
                ...state,
                integrations: newIntegrations,
                status,
                error,
                indexes,
                noResults,
            };
        }
        case types.REFRESH_INTEGRATIONS: {
            const { integrationId, integration } = action.payload;
            const newIntegrations = [...state.integrations];

            newIntegrations[state.indexes[integrationId]] = integration;

            return { ...state, integrations: newIntegrations };
        }
        case types.SET_INTEGRATIONS_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_INTEGRATIONS:
            return { ...initialState };
        default:
            return state;
    }
};
