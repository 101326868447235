import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AddWhite = (props) => {
    return (
        <SvgIcon fontSize="small" width={15} height={15} viewBox="0 0 15 15" {...props}>
            <defs>
                <rect id="path-241" x="0" y="0" width="15" height="15" />
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Add/Small/white">
                    <mask id="mask-2" fill={props.htmlColor ? props.htmlColor : '#fff'}>
                        <use xlinkHref="#path-1231" />
                    </mask>
                    <g id="Background" />
                    <line
                        x1="7.5"
                        y1="0"
                        x2="7.5"
                        y2="15"
                        id="Line-2"
                        stroke={props.htmlColor ? props.htmlColor : '#FFFFFF'}
                        mask="url(#mask-1231)"
                        transform="translate(7.500000, 7.500000) rotate(-270.000000) translate(-7.500000, -7.500000) "
                    />
                    <line
                        x1="7.5"
                        y1="0"
                        x2="7.5"
                        y2="15"
                        id="Line-2"
                        stroke={props.htmlColor ? props.htmlColor : '#FFFFFF'}
                        mask="url(#mask-1231)"
                        transform="translate(7.500000, 7.500000) rotate(-360.000000) translate(-7.500000, -7.500000) "
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

export default AddWhite;
