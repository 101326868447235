import * as types from './types';

const list = (action, list = []) => {
    const { payload, append } = action;
    switch (action.type) {
        case types.INIIALIZE:
            return append ? [...list, ...payload] : payload;

        case types.CHILD_ADDED:
            return list.findIndex((d) => d.key === payload.key) === -1 ? [...list, payload] : [...list];

        case types.CHILD_CHANGED:
            return list.map((child) => (payload.key === child.key ? payload : child));

        case types.CHILD_REMOVED:
            return list.filter((child) => payload.key !== child.key);
        default:
            return list;
    }
};

const lists = (state = {}, action) => {
    const { location } = action;

    switch (action.type) {
        case types.INIIALIZE:
            return {
                ...state,
                [location]: list(action, state[action.location]),
            };

        case types.CHILD_ADDED:
        case types.CHILD_CHANGED:
        case types.CHILD_REMOVED:
            return { ...state, [location]: list(action, state[action.location]) };

        case types.DESTROY:
            const newState = { ...state };
            delete newState[location];
            return { ...newState };

        default:
            return state;
    }
};

export default lists;
