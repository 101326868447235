import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ContentHub = (props) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.5 4C10.5 3.17181 11.1718 2.5 12 2.5C12.8282 2.5 13.5 3.17181 13.5 4C13.5 4.82819 12.8282 5.5 12 5.5C11.1718 5.5 10.5 4.82819 10.5 4"
                fill="none"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
            />
            <path
                d="M10.5 20C10.5 20.8282 11.1718 21.5 12 21.5C12.8282 21.5 13.5 20.8282 13.5 20C13.5 19.1718 12.8282 18.5 12 18.5C11.1718 18.5 10.5 19.1718 10.5 20"
                fill="none"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
            />
            <path
                d="M4.25004 9.29853C3.5328 8.8845 3.28707 7.9671 3.70114 7.25073C4.11521 6.53277 5.03191 6.28706 5.74996 6.70109C6.4672 7.11512 6.71293 8.03252 6.29886 8.74968C5.88479 9.46685 4.96649 9.71335 4.25004 9.29853"
                fill="none"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
            />
            <path
                d="M18.2504 17.2985C18.9669 17.7133 19.8852 17.4668 20.2985 16.7504C20.7134 16.0323 20.4668 15.1156 19.7496 14.7008C19.0323 14.2875 18.1148 14.5324 17.7015 15.2505C17.2866 15.9669 17.5332 16.8844 18.2504 17.2985"
                fill="none"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
            />
            <path
                d="M5.74996 17.2985C5.03271 17.7133 4.11521 17.4668 3.70114 16.7504C3.28707 16.0323 3.5328 15.1156 4.25004 14.7008C4.96729 14.2875 5.88479 14.5324 6.29886 15.2505C6.71293 15.9669 6.4672 16.8844 5.74996 17.2985"
                fill="none"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
            />
            <path
                d="M19.7496 9.29853C20.4668 8.8845 20.7134 7.9671 20.2985 7.25073C19.8852 6.53277 18.9677 6.28706 18.2504 6.70109C17.5332 7.11512 17.2866 8.03252 17.7015 8.74968C18.1148 9.46685 19.0331 9.71335 19.7496 9.29853"
                fill="none"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
            />
            <circle cx="12" cy="12" r="3.5" fill="none" stroke={props.htmlColor ? props.htmlColor : '#fff'} />
            <path d="M12 8.5V5.5" fill="none" stroke={props.htmlColor ? props.htmlColor : '#fff'} />
            <path d="M12 18.5V15.5" fill="none" stroke={props.htmlColor ? props.htmlColor : '#fff'} />
            <path d="M15.0313 10.25L17.6294 8.75" fill="none" stroke={props.htmlColor ? props.htmlColor : '#fff'} />
            <path d="M6.37067 15.25L8.96875 13.75" fill="none" stroke={props.htmlColor ? props.htmlColor : '#fff'} />
            <path d="M15.0313 13.75L17.6294 15.25" fill="none" stroke={props.htmlColor ? props.htmlColor : '#fff'} />
            <path
                d="M6.37067 8.75012L8.96875 10.2501"
                fill="none"
                stroke={props.htmlColor ? props.htmlColor : '#fff'}
            />
        </SvgIcon>
    );
};

export default ContentHub;
