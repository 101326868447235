import { canUseWebP } from './canUseWebP';

const { REACT_APP_STORAGE_BUCKET_URL } = process.env || '';

export const getValidLink = (link) => {
    if (link) {
        if (link.indexOf('http') < 0) {
            return `${REACT_APP_STORAGE_BUCKET_URL}/${link}`;
        }

        const params = new URL(link);

        if (['dev.truetour.app', 'dev-truetour.app'].includes(params.host)) {
            return `${REACT_APP_STORAGE_BUCKET_URL}${params.pathname}${params.search}`;
        }

        if (['qa.truetour.app', 'qa-truetour.app'].includes(params.host)) {
            return `${REACT_APP_STORAGE_BUCKET_URL}${params.pathname}${params.search}`;
        }

        if (['demo.truetour.app', 'demo-truetour.app'].includes(params.host)) {
            return `${REACT_APP_STORAGE_BUCKET_URL}${params.pathname}${params.search}`;
        }
    }

    return link;
};

const getThumbnail = (size = 'tiny', item = {}, photoURL = '') => {
    let photo = photoURL;
    if (item && item.thumbnails && item.thumbnails.full) {
        const { thumbnails, version } = item;
        const { full } = thumbnails;
        const thumbnailSize = thumbnails[size];
        if (canUseWebP() && full && full.webp) {
            if (thumbnailSize) {
                photo = thumbnailSize.webp;
            } else {
                photo = full.webp;
            }
        } else if (thumbnailSize) {
            photo = thumbnailSize.jpg;
        } else {
            photo = full.jpg;
        }
        if (version) {
            photo = `${photo}?v=${version}`;
        }
    }

    return getValidLink(photo);
};

export default getThumbnail;
