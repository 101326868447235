import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    contactNotes: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const contactNotesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CONTACT_NOTES: {
            const { contactNotes, status, error, query } = action.payload;
            const newContactNotes = getNewList(query, state.contactNotes, contactNotes);
            const indexes = getListIndexes(newContactNotes);
            return {
                ...state,
                contactNotes: newContactNotes,
                status,
                error,
                indexes,
            };
        }
        case types.REFRESH_CONTACT_NOTES: {
            const { changes } = action.payload;
            const newContactNotes = handleListRefresh(state.contactNotes, state.indexes, changes);

            return { ...state, contactNotes: newContactNotes };
        }
        case types.SET_CONTACT_NOTES_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_CONTACT_NOTES:
            return { ...initialState };
        default:
            return state;
    }
};
