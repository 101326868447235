import { REQUEST_STATUSES } from '../../../constants/requestStatuses';

const initialState = {
    experiences: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
    noResults: false,
};

export const searchedExperiencesSelector = (propertyId) => (state) => {
    return state.searchedExperiences[propertyId]
        ? { ...initialState, ...state.searchedExperiences[propertyId] }
        : initialState;
};
