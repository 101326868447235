import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    hotspots: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const hotspotsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_HOTSPOTS: {
            const { hotspots, status, error, query } = action.payload;
            const newHotspots = getNewList(query, state.hotspots, hotspots);
            const indexes = getListIndexes(newHotspots);
            return { ...state, hotspots: newHotspots, status, error, indexes };
        }
        case types.REFRESH_HOTSPOTS: {
            const { changes } = action.payload;
            const newHotspots = handleListRefresh(state.hotspots, state.indexes, changes);

            return { ...state, hotspots: newHotspots };
        }
        case types.SET_HOTSPOTS_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_HOTSPOTS:
            return { ...initialState };
        default:
            return state;
    }
};
