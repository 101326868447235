import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    contactEvents: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
};

export const contactEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CONTACT_EVENTS: {
            const { contactEvents, status, error, query } = action.payload;
            const newContactEvents = getNewList(query, state.contactEvents, contactEvents);
            const indexes = getListIndexes(newContactEvents);
            return {
                ...state,
                contactEvents: newContactEvents,
                status,
                error,
                indexes,
            };
        }
        case types.CREATE_CONTACT_EVENT: {
            const { contactEvent, status, error } = action.payload;

            return {
                ...state,
                contactEvents: [contactEvent, ...state.contactEvents],
                status,
                error,
            };
        }
        case types.REFRESH_CONTACT_EVENTS: {
            const { changes } = action.payload;
            const newContactEvents = handleListRefresh(state.contactEvents, state.indexes, changes);

            return { ...state, contactEvents: newContactEvents };
        }
        case types.SET_CONTACT_EVENTS_REQUEST_STATUS:
            return { ...state, ...action.payload };
        case types.RESET_CONTACT_EVENTS:
            return { ...initialState };
        default:
            return state;
    }
};
