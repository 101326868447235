import areIntlLocalesSupported from 'intl-locales-supported';
import intl from 'intl';
import enMessages from './en';
import deMessages from './de';
import ruMessages from './ru';
import bsMessages from './bs';
import esMessages from './es';

const localesMyAppSupports = [];

if (global.Intl) {
    if (!areIntlLocalesSupported(localesMyAppSupports)) {
        const IntlPolyfill = intl;
        Intl.NumberFormat = IntlPolyfill.NumberFormat;
        Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
    }
} else {
    global.Intl = intl;
}

const locales = [
    {
        locale: 'en',
        messages: enMessages,
    },
    {
        locale: 'de',
        messages: deMessages,
    },
    {
        locale: 'en',
        messages: enMessages,
    },
    {
        locale: 'de',
        messages: deMessages,
    },
    {
        locale: 'bs',
        messages: bsMessages,
    },
    {
        locale: 'ru',
        messages: ruMessages,
    },
    {
        locale: 'es',
        messages: esMessages,
    },
];

export function getLocaleMessages() {
    return enMessages; // Default locale
}

export default locales;
