import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowLeftIcon = (props) => {
    return (
        <SvgIcon width="10px" height="20px" viewBox="0 0 10 20" {...props}>
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g
                    id="Media-Library_url-upload"
                    transform="translate(-618.000000, -437.000000)"
                    stroke={props.htmlColor || '#8F0339'}
                >
                    <polyline id="Path-6-Copy-2" points="627 437 619 447 627 457" />
                </g>
            </g>
        </SvgIcon>
    );
};

export default ArrowLeftIcon;
