import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Home = (props) => {
    const { style, htmlColor, ...rest } = props;

    return (
        <SvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: '1em', height: '1em', fontSize: '1em', ...style }}
            {...rest}
        >
            <path
                fill={htmlColor || 'white'}
                d="M20.8125 11.625C21.0312 11.8125 21.0312 12.125 20.8438 12.3438C20.6875 12.5625 20.375 12.5625 20.1562 12.375L19 11.3438V17.5C19 18.9062 17.875 20 16.5 20H7.5C6.09375 20 5 18.9062 5 17.5V11.3438L3.8125 12.375C3.59375 12.5625 3.28125 12.5625 3.125 12.3438C2.9375 12.125 2.9375 11.8125 3.15625 11.625L11.6562 4.15625C11.8438 3.96875 12.125 3.96875 12.3125 4.15625L20.8125 11.625ZM7.5 19H9.5V14C9.5 13.4688 9.9375 13 10.5 13H13.5C14.0312 13 14.5 13.4688 14.5 14V19H16.5C17.3125 19 18 18.3438 18 17.5V10.4688L12 5.1875L6 10.4688V17.5C6 18.3438 6.65625 19 7.5 19ZM10.5 19H13.5V14H10.5V19Z"
            />
        </SvgIcon>
    );
};

export default Home;
