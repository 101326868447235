import React from 'react';
import { Checkbox, LabelText, FormControlLabel } from '../../..';
import { NOTIFICATION_OPTIONS } from '../../../../../../constants';

function Phone(props) {
    const { name, values, onChange } = props;

    const labelMarkup = <LabelText option={NOTIFICATION_OPTIONS.SMS} />;

    const controlMarkup = <Checkbox name={name} values={values} onChange={onChange} />;

    return <FormControlLabel label={labelMarkup} control={controlMarkup} />;
}

export default Phone;
