import { getAuth } from 'firebase/auth';
import firebaseApp from '../config/firebase';
import { ROLES } from '../constants/roles';

const auth = getAuth(firebaseApp);

export default function isGranted(state, grant, opts = {}) {
    const { auth, currentUser } = state;
    const propertyId = opts.propertyId || state.propertyId;

    const { isAuthorised: authed } = auth || {};
    const { role, properties = [], propertyRoles = {} } = currentUser.user;

    // If not logged in return false
    if (authed !== true) {
        return false;
    }

    // If COACH ADMIN return true
    if (role === ROLES.COACH_ADMIN) {
        return true;
    }

    if (!grant) {
        return false;
    }

    // If not in a property, return false
    if (!properties.length) {
        return false;
    }

    return !!propertyId && propertyRoles[propertyId] === grant;
}

export function checkPropertyAdminOrAdmin(state) {
    const { currentUser, currentProperty } = state;
    const { user = {} } = currentUser;
    const { property = {} } = currentProperty;
    const { role, propertyRoles = {} } = user;
    const { id: propertyId } = property;

    if (role === ROLES.COACH_ADMIN) {
        return true;
    }

    return Boolean(propertyId && propertyRoles[property] === ROLES.PROPERTY_ADMIN);
}

const localStorageAuthKey = `${window.location.host}:ttx:isAuthorised`;

export function saveAuthorisation(user) {
    if (typeof Storage !== 'undefined') {
        try {
            localStorage.setItem(localStorageAuthKey, Boolean(user));
        } catch (ex) {
            console.log(ex);
        }
    } else {
        // No web storage Support.
    }
    return false;
}

export const getAccessToken = () => {
    return auth.currentUser?.accessToken;
};

export function isAuthorised() {
    if (typeof Storage !== 'undefined') {
        try {
            const authorisedCheck = localStorage.getItem(localStorageAuthKey) === 'true';
            return authorisedCheck;
        } catch (ex) {
            return false;
        }
    } else {
        // No web storage Support.
    }
    return false;
}
