import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import { NTF_CATEGORIES } from '../../../constants/ntfCategories';

const initialState = {
    notifications: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
    isRead: 0,
    hasOpened: 0,
};

export const notificationsSelector = (activeCategory) => (state) => {
    const stateNotifications = {
        [NTF_CATEGORIES.ENGAGEMENT]: state.notifications[NTF_CATEGORIES.ENGAGEMENT]
            ? { ...initialState, ...state.notifications[NTF_CATEGORIES.ENGAGEMENT] }
            : initialState,
        [NTF_CATEGORIES.GENERAL]: state.notifications[NTF_CATEGORIES.GENERAL]
            ? { ...initialState, ...state.notifications[NTF_CATEGORIES.GENERAL] }
            : initialState,
        [NTF_CATEGORIES.CONTENT]: state.notifications[NTF_CATEGORIES.CONTENT]
            ? { ...initialState, ...state.notifications[NTF_CATEGORIES.CONTENT] }
            : initialState,
    };

    return stateNotifications[activeCategory];
};

export const notificationsOptionsSelector = (state) => {
    const { isRead, hasOpened } = state.notifications;

    return { isRead, hasOpened };
};
