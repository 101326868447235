import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconStop = (props) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="12" cy="12" r="11.5" stroke="white" />
            <path d="M8 8H16V16H8V8Z" fill="white" />
        </SvgIcon>
    );
};

export default IconStop;
