import { getDatabase, ref, off, onValue } from 'firebase/database';
import * as types from './types';
import * as selectors from './selectors';
import * as initSelectors from '../initialization/selectors';
import { logError } from '../errors/actions';
import { logLoading } from '../loadings/actions';

export const initialize = (list, location, path, append) => {
    return {
        type: types.INIIALIZE,
        payload: list,
        path,
        location,
        append,
        locationValue: true,
    };
};

export const childAdded = (child, location) => {
    return {
        type: types.CHILD_ADDED,
        payload: child,
        location,
    };
};

export const childChanged = (child, location) => {
    return {
        type: types.CHILD_CHANGED,
        payload: child,
        location,
    };
};

export const childRemoved = (child, location) => {
    return {
        type: types.CHILD_REMOVED,
        payload: child,
        location,
    };
};

export const destroy = (location) => {
    return {
        type: types.DESTROY,
        location,
    };
};

export const unWatch = (path) => {
    return {
        type: types.UNWATCH,
        path,
    };
};

const getPayload = (snapshot) => {
    return { key: snapshot.key, val: snapshot.val() };
};

export const getRef = (firebaseApp, path) => {
    if (typeof path === 'string' || path instanceof String) {
        const db = getDatabase(firebaseApp);
        return ref(db, path);
    }

    return path;
};

export const getLocation = (firebaseApp, path) => {
    if (typeof path === 'string' || path instanceof String) {
        return path;
    }

    const db = getDatabase(firebaseApp);
    const dbRef = ref(db);
    const dbRoot = dbRef.root.toString;
    return path.toString().substring(dbRoot.length);
};

export function watchList(firebaseApp, firebasePath, reduxPath = false, append = false) {
    const docRef = getRef(firebaseApp, firebasePath);
    const path = docRef.toString();
    const location = reduxPath || getLocation(firebaseApp, firebasePath);

    return (dispatch, getState) => {
        let initialized = false;
        const isInitialized = initSelectors.isInitialised(getState(), path, location);
        const persistetList = getState().lists ? getState().lists[location] : [];

        if (!isInitialized) {
            dispatch(initialize(persistetList, location, path, append));
            dispatch(logLoading(location));
            onValue(
                docRef,
                (snapshot) => {
                    initialized = true;

                    const list = [];

                    snapshot.forEach((childSnapshot) => {
                        const childKey = childSnapshot.key;
                        const childData = childSnapshot.val();

                        list.push({ key: childKey, val: childData });
                    });

                    dispatch(initialize(list, location, path, append));
                },
                (err) => {
                    console.error(err);
                    dispatch(logError(location, err));
                },
            );

            ref.on(
                'child_added',
                (snapshot) => {
                    if (initialized) {
                        dispatch(childAdded(getPayload(snapshot), location));
                    }
                },
                (err) => {
                    console.error(err);
                    dispatch(logError(location, err));
                },
            );

            ref.on(
                'child_changed',
                (snapshot) => {
                    dispatch(childChanged(getPayload(snapshot), location));
                },
                (err) => {
                    console.error(err);
                    dispatch(logError(location, err));
                },
            );

            ref.on(
                'child_removed',
                (snapshot) => {
                    dispatch(childRemoved(getPayload(snapshot), location));
                },
                (err) => {
                    console.error(err);
                    dispatch(logError(location, err));
                },
            );
        }
    };
}

export const unwatchList = (firebaseApp, firebasePath) => {
    return (dispatch) => {
        const docRef = getRef(firebaseApp, firebasePath);
        off(docRef);
        dispatch(unWatch(docRef.toString()));
    };
};

export const destroyList = (firebaseApp, firebasePath, reduxPath = false) => {
    return (dispatch, getState) => {
        const docRef = getRef(firebaseApp, firebasePath);
        const locations = getState().initialization[docRef.toString()];

        off(docRef);
        dispatch(unWatch(docRef.toString()));

        if (reduxPath) {
            dispatch(destroy(reduxPath));
        } else if (locations) {
            Object.keys(locations).forEach((location) => {
                dispatch(destroy(location));
            });
        }
    };
};

export const unwatchAllLists = (firebaseApp) => {
    return (dispatch, getState) => {
        const allLists = selectors.getAllLists(getState());

        Object.keys(allLists).forEach((key) => {
            const docRef = getRef(firebaseApp, key);
            off(docRef);
            dispatch(unWatch(ref.toString()));
        });
    };
};

export const destroyAllLists = (firebaseApp) => {
    return (dispatch, getState) => {
        const allLists = selectors.getAllLists(getState());

        Object.keys(allLists).forEach((key) => {
            const docRef = getRef(firebaseApp, key);
            off(docRef);
            dispatch(destroyList(firebaseApp, ref.toString()));
        });
    };
};
