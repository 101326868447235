import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ThreeDeeRedIcon = (props) => {
    return (
        <SvgIcon
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.34868 11.2383L12.0303 13.3246L16.709 11.2383L12.0303 9.15193L7.34868 11.2383Z"
                stroke="#8F0339"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M16.6908 11.2673L16.709 17.1922L12.0303 19.4616L7.34868 17.1922V11.2209"
                stroke="#8F0339"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path d="M20.8561 18.247L14.3279 15.3354" stroke="#8F0339" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M3.19643 18.247L9.72468 15.3354" stroke="#8F0339" stroke-miterlimit="10" strokeLinecap="round" />
            <path
                d="M12.0023 4.3138C12.0088 6.90865 12.0187 11.2005 11.9957 11.2005"
                stroke="#8F0339"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M12.0849 19.3977C12.0653 19.3977 12.0849 13.345 12.0849 13.345"
                stroke="#8F0339"
                strokeMiterlimit="10"
            />
            <path d="M10.3156 5.67217L12.0269 4L13.7381 5.67217" stroke="#8F0339" strokeLinecap="round" />
            <path d="M20.4927 16.2626L21.1896 18.5272L18.872 19.2082" stroke="#8F0339" strokeLinecap="round" />
            <path d="M3.6347 16.2776L2.50579 18.3694L4.64646 19.4725" stroke="#8F0339" strokeLinecap="round" />
        </SvgIcon>
    );
};

export default ThreeDeeRedIcon;
