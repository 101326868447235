import * as types from './types';

const defaultState = {};

export const experienceLanguagesReducer = (state = defaultState, action) => {
    if (action.type === types.ON_EXPERIENCE_LANGUAGE_CHANGED) {
        return { ...state, [action.id]: action.value };
    }

    return state;
};
