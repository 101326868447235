import React from 'react';

const Error = ({ htmlColor = '#ffffff', ...props }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <line x1="10" y1="5" x2="10" y2="10" stroke={htmlColor || `black`} strokeWidth="2" strokeLinecap="round" />
            <circle cx="10" cy="14" r="1" fill={htmlColor || `black`} stroke={htmlColor || `black`} />
            <circle cx="10" cy="10" r="9" stroke={htmlColor || `black`} strokeWidth="1.5" />
        </svg>
    );
};

export default Error;
