import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MailIcon = (props) => {
    const { style, htmlColor, ...rest } = props;

    return (
        <SvgIcon
            width="49"
            height="32"
            viewBox="0 0 49 32"
            xmlns="http://www.w3.org/2000/svg"
            fill={htmlColor}
            style={{
                height: '1em',
                width: '1.5458984375em',
                fontSize: '1em',
                ...style,
            }}
            {...rest}
        >
            <path d="M48 0h-46.545c-0.803 0-1.455 0.651-1.455 1.455v29.091c0 0.803 0.651 1.455 1.455 1.455h46.545c0.803 0 1.455-0.651 1.455-1.455v-29.091c0-0.803-0.651-1.455-1.455-1.455zM46.545 2.909v26.182h-43.636v-26.182h43.636z" />
            <path d="M42.449 4.465c0.633-0.495 1.547-0.382 2.042 0.251 0.44 0.563 0.4 1.347-0.059 1.862l-0.192 0.179-18.618 14.545c-0.451 0.352-1.058 0.403-1.554 0.151l-0.237-0.151-18.618-14.545c-0.633-0.495-0.745-1.409-0.251-2.042 0.44-0.563 1.211-0.714 1.821-0.393l0.22 0.143 17.721 13.842 17.724-13.842z" />
        </SvgIcon>
    );
};

export default MailIcon;
