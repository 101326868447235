import { Card as MUICard } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import React from 'react';
import { Body, Title } from './components';

const styles = () => ({
    card: {
        padding: '20px 24px',
        color: '#000',
        marginBottom: '24px',
        borderRadius: '2px',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    cardHighlighted: {
        border: '1px solid #8f0339',
    },
});

function Card({ children, classes, isHighlighted }) {
    const className = classNames(classes.card, isHighlighted && classes.cardHighlighted);

    return (
        <MUICard className={className} elevation={0}>
            {children}
        </MUICard>
    );
}

Card.Body = Body;
Card.Title = Title;

export default withStyles(styles)(Card);
