import React from 'react';
import { Accordion, FormControlLabel } from '..';
import { ImageIcon } from '../../../../../../../icons';
import { USER_FIELDS } from '../../../../constants';

function Content(props) {
    const { email, values, onChange } = props;

    const iconMarkup = <ImageIcon htmlColor="#000" style={{ fontSize: '16px' }} />;

    return (
        <Accordion
            icon={iconMarkup}
            label="Content Notifications"
            disclosure="Content notifications notify you of new content updates to your Content Library and experiences"
        >
            <FormControlLabel.Phone
                name={USER_FIELDS.CONTENT_NOTIFICATIONS_SMS_ENABLED}
                values={values}
                onChange={onChange}
            />

            <FormControlLabel.Email
                name={USER_FIELDS.CONTENT_NOTIFICATIONS_EMAIL_ENABLED}
                values={values}
                onChange={onChange}
                email={email}
            />

            <FormControlLabel.Push
                name={USER_FIELDS.CONTENT_NOTIFICATIONS_PUSH_ENABLED}
                values={values}
                onChange={onChange}
            />
        </Accordion>
    );
}

export default Content;
