/* eslint-disable no-unused-vars */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import pMinDelay from 'p-min-delay';
import loadable from '@loadable/component';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactRedux from 'react-redux/lib';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import LoadingComponent from './containers/LoadingComponent/LoadingComponent';
import { wdyr, currentEnv } from './config/envSettings';
import { AwsRum } from 'aws-rum-web';

if (wdyr()) {
    whyDidYouRender(React, {
        trackAllPureComponents: true,
        trackHooks: true,
        trackExtraHooks: [[ReactRedux, 'useSelector']],
    });
}

const loadWithTimeout = import('./App');
const loadWithDelay = pMinDelay(loadWithTimeout, 1000);

const AppAsync = loadable(() => loadWithDelay, {
    fallback: LoadingComponent.Splash({
        pastDelay: true,
        error: false,
        timedOut: false,
    }),
});

const reactApp = document.createElement('div');
reactApp.style.height = '100%';

document.body.appendChild(reactApp);
const root = createRoot(reactApp);
root.render(<AppAsync />);

const env = currentEnv();

if (env === 'qa' || env === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_IO_DSN,
        integrations: [new BrowserTracing()],

        tracesSampleRate: 1.0,
    });
}

if (
    process.env.REACT_APP_AWS_RUM_ENABLED === 'true' &&
    process.env.REACT_APP_AWS_RUM_ADD_XRAY_TRACE_ID_HEADER === 'true' &&
    process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN &&
    process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID &&
    process.env.REACT_APP_AWS_RUM_APPLICATION_ID
) {
    const aws_rum_telemetries = process.env.REACT_APP_AWS_RUM_TELEMETRIES;

    try {
        //console.log(aws_rum_telemetries);
        const aws_rum_telemetries_string = `^(https:\\/\\/(.*\\.amazonaws\\.com\\/|${aws_rum_telemetries}\\.vmeng\\.io\\/)|(?:.*\\/api\\/)).*`;
        //console.log(aws_rum_telemetries_string);
        const config = {
            sessionSampleRate: 1,
            guestRoleArn: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
            identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: [
                [
                    'http',
                    {
                        addXRayTraceIdHeader: true,
                        urlsToInclude: [new RegExp(aws_rum_telemetries_string)],
                        urlsToExclude: [
                            new RegExp(
                                '^(https:\\/\\/metrics1\\.visitingmedia\\.com\\/api\\/event|https:\\/\\/.*\\.launchdarkly\\.com\\/.*|https:\\/\\/app\\.launchdarkly\\.com\\/sdk\\/evalx\\/\\/users\\/.*)',
                            ),
                        ],
                    },
                ],
                'errors',
                'performance',
            ],
            allowCookies: true,
            enableXRay: true,
        };

        const APPLICATION_ID = process.env.REACT_APP_AWS_RUM_APPLICATION_ID;
        const APPLICATION_VERSION = '1.0.0';
        const APPLICATION_REGION = 'us-west-2';

        const awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
    }
} else if (
    process.env.REACT_APP_AWS_RUM_ENABLED === 'true' &&
    process.env.REACT_APP_AWS_RUM_ADD_XRAY_TRACE_ID_HEADER !== 'true' &&
    process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN &&
    process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID &&
    process.env.REACT_APP_AWS_RUM_APPLICATION_ID
) {
    try {
        const config = {
            sessionSampleRate: 1,
            guestRoleArn: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
            identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['http', 'errors', 'performance'],
            allowCookies: true,
            enableXRay: true,
        };

        const APPLICATION_ID = process.env.REACT_APP_AWS_RUM_APPLICATION_ID;
        const APPLICATION_VERSION = '1.0.0';
        const APPLICATION_REGION = 'us-west-2';

        const awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
    }
}

export default AppAsync;
