import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Templates = (props) => {
    return (
        <SvgIcon width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect
                x="0.5"
                y="0.5"
                width="16"
                height="2"
                stroke={props.htmlColor ? props.htmlColor : 'rgba(0,0,0,.5)'}
                fill="none"
            />
            <rect
                x="0.5"
                y="4.5"
                width="3"
                height="10"
                stroke={props.htmlColor ? props.htmlColor : 'rgba(0,0,0,.5)'}
                fill="none"
            />
            <rect
                x="13.5"
                y="4.5"
                width="3"
                height="10"
                stroke={props.htmlColor ? props.htmlColor : 'rgba(0,0,0,.5)'}
                fill="none"
            />
            <line
                x1="5"
                y1="4.5"
                x2="12"
                y2="4.5"
                stroke={props.htmlColor ? props.htmlColor : 'rgba(0,0,0,.5)'}
                fill="none"
            />
            <line
                x1="5"
                y1="14.5"
                x2="12"
                y2="14.5"
                stroke={props.htmlColor ? props.htmlColor : 'rgba(0,0,0,.5)'}
                fill="none"
            />
        </SvgIcon>
    );
};

export default Templates;
