import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Image360 = (props) => {
    return (
        <SvgIcon width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.0498 12.63C13.0798 12.5 12.0498 12.43 10.9799 12.43C9.89986 12.43 8.85987 12.5 7.87988 12.64"
                stroke={props.htmlColor ? props.htmlColor : '#333333'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.9996 15.01C20.9996 15.86 18.9597 16.61 15.9297 17V5.28C18.9597 4.89 20.9996 4.14 20.9996 3.29V15"
                stroke={props.htmlColor ? props.htmlColor : '#333333'}
                strokeLinejoin="round"
            />
            <path
                d="M2.30029 2.16C4.02028 1.47 7.27024 1 11.0002 1C14.7302 1 17.9801 1.47 19.7001 2.16"
                stroke={props.htmlColor ? props.htmlColor : '#333333'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 15.01C1 15.86 3.02998 16.6 6.03995 17V5.27C3.02998 4.88 1 4.14 1 3.29V15"
                stroke={props.htmlColor ? props.htmlColor : '#333333'}
                strokeLinejoin="round"
            />
            <path
                d="M13.9697 9.57H7.96973"
                stroke={props.htmlColor ? props.htmlColor : '#333333'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.96973 7.79L10.2397 5.57L11.7797 7.57L13.0897 6.61L13.9697 7.55"
                stroke={props.htmlColor ? props.htmlColor : '#333333'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.43 3.5C12.7656 3.5 13.0643 3.67983 13.228 3.95192C13.3024 4.08361 13.3515 4.24909 13.3599 4.43903C13.3551 4.94873 12.9408 5.36 12.43 5.36C11.9161 5.36 11.5 4.94386 11.5 4.43C11.5 3.91614 11.9161 3.5 12.43 3.5Z"
                stroke={props.htmlColor ? props.htmlColor : '#333333'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};

export default Image360;
