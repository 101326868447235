import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const File = (props) => {
    return (
        <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 15L1 1L15 1L15 10.5127L10.1346 15L1 15Z"
                stroke={props.htmlColor || '#333333'}
                strokeLinejoin="round"
            />
            <path
                d="M10.3333 15L10.3333 10.2469L15 10.2469"
                stroke={props.htmlColor || '#333333'}
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};

export default File;
