import * as types from './types';

const initialState = {
    video: {},
    error: null,
    noResults: false,
};

export const currentUnivVideoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_CURRENT_UNIV_VIDEO:
            return { ...state, ...action.payload };
        case types.REFRESH_CURRENT_UNIV_VIDEO:
            return { ...state, ...action.payload };
        case types.RESET_CURRENT_UNIV_VIDEO:
            return { ...initialState };
        default:
            return state;
    }
};
