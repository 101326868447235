import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import { getAnalytics } from 'firebase/analytics';
import firebaseConfig from './firebaseConfig';

let firebaseApp = null;

const envConfig = () => {
    if (process.env.REACT_APP_LOCAL === 'true') {
        return firebaseConfig.firebase_config_local;
    }
    if (process.env.REACT_APP_QA === 'true') {
        return firebaseConfig.firebase_config_qa;
    }
    if (process.env.REACT_APP_DEMO === 'true') {
        return firebaseConfig.firebase_config_demo;
    }
    if (process.env.REACT_APP_STAGE === 'true') {
        return firebaseConfig.firebase_config_staging;
    }
    if (process.env.REACT_APP_PROD === 'true') {
        return firebaseConfig.firebase_config_prod;
    }
    if (process.env.REACT_APP_DEV_2 === 'true') {
        return firebaseConfig.firebase_config_dev_2;
    }
    if (process.env.REACT_APP_DEV === 'true') {
        return firebaseConfig.firebase_config_dev;
    }

    return firebaseConfig.firebase_config_dev;
};

// Firebase app should be initialized only once
if (firebase.apps.length === 0) {
    firebaseApp = initializeApp(envConfig());
    getAnalytics(firebaseApp);
} else {
    [firebaseApp] = firebase.apps;
}

export { firebaseApp };
export default firebaseApp;
