import React from 'react';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
    cardBody: {
        marginTop: '30px',
    },
});

function Body({ children, classes, style }) {
    return (
        <div className={classes.cardBody} style={style}>
            {children}
        </div>
    );
}

export default withStyles(styles)(Body);
