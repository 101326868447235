import { SECTIONS } from '../../../constants/sections';

const initialState = {
    [SECTIONS.PROPERTIES]: {
        sortField: 'name',
        sortOrientation: 'asc',
        filterFieldName: '',
        filterFieldValue: '',
        searchText: '',
        offset: 0,
        limit: 100,
    },
    [SECTIONS.MEDIA_LIBS]: {
        sortField: 'dateAdded',
        sortOrientation: 'asc',
        searchText: '',
        offset: 0,
        limit: 100,
    },
    [SECTIONS.USERS]: {
        sortField: 'name',
        sortOrientation: 'asc',
        searchText: '',
        offset: 0,
        limit: 100,
    },
    [SECTIONS.COACHES]: {
        sortField: 'name',
        sortOrientation: 'asc',
        searchText: '',
        offset: 0,
        limit: 100,
    },
    [SECTIONS.MEMBERS]: {
        sortField: 'name',
        sortOrientation: 'asc',
        searchText: '',
        offset: 0,
        limit: 100,
    },
    [SECTIONS.CONTACTS]: {
        sortField: 'name',
        sortOrientation: 'asc',
        searchText: '',
        offset: 0,
        limit: 100,
    },
    [SECTIONS.EXPERIENCES]: {
        sortField: 'dateAdded',
        sortOrientation: 'asc',
        searchText: '',
        offset: 0,
        limit: 100,
    },
    [SECTIONS.MEDIA_ITEMS]: {
        offset: 0,
        limit: 100,
        sortField: 'dateAdded',
        sortOrientation: 'asc',
        searchText: '',
        searchedTypes: [],
        searchedTags: [],
        searchedMembers: [],
    },
    [SECTIONS.ICONS]: {
        sortField: 'name',
        sortOrientation: 'asc',
        searchText: '',
        offset: 0,
        limit: 100,
    },
    [SECTIONS.UNIVERSITY]: {
        sortField: 'order',
        sortOrientation: 'asc',
        searchText: '',
    },
};

export const queriesSelector = (name, path) => (state) => {
    return { ...initialState[name], ...state.queries[path] } || initialState[name] || {};
};
