import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Help = (props) => {
    const { style, htmlColor, ...rest } = props;

    return (
        <SvgIcon
            width="32"
            height="32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: '1em', height: '1em', fontSize: '1em', ...style }}
            {...rest}
        >
            <path
                fill={htmlColor || '#333'}
                d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 1.778c7.855 0 14.222 6.367 14.222 14.222s-6.367 14.222-14.222 14.222c-7.855 0-14.222-6.367-14.222-14.222s6.367-14.222 14.222-14.222z"
            />
            <path
                fill={htmlColor || '#333'}
                d="M14.427 20.691v-0.563c0-0.813 0.125-1.479 0.375-2 0.25-0.528 0.715-1.083 1.396-1.667 0.945-0.799 1.538-1.399 1.781-1.802 0.25-0.403 0.375-0.889 0.375-1.458 0-0.708-0.229-1.254-0.687-1.635-0.451-0.389-1.104-0.583-1.958-0.583-0.549 0-1.083 0.066-1.604 0.198-0.521 0.125-1.118 0.358-1.792 0.698l-0.615-1.406c1.313-0.688 2.684-1.031 4.115-1.031 1.326 0 2.358 0.326 3.094 0.979s1.104 1.573 1.104 2.76c0 0.507-0.070 0.955-0.208 1.344-0.132 0.382-0.33 0.746-0.594 1.094-0.264 0.34-0.833 0.892-1.708 1.656-0.701 0.597-1.167 1.094-1.396 1.49-0.222 0.396-0.333 0.924-0.333 1.583v0.344h-1.344zM13.917 23.785c0-0.944 0.417-1.417 1.25-1.417 0.403 0 0.712 0.122 0.927 0.365 0.222 0.243 0.333 0.594 0.333 1.052 0 0.444-0.111 0.792-0.333 1.042-0.222 0.243-0.531 0.365-0.927 0.365-0.361 0-0.66-0.108-0.896-0.323-0.236-0.222-0.354-0.583-0.354-1.083z"
            />
        </SvgIcon>
    );
};

export default Help;
