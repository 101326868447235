import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    experiences: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
    noResults: false,
};

export const experiencesReducer = (state = {}, action) => {
    switch (action.type) {
        case types.GET_EXPERIENCES: {
            const { propertyId, experienceTab, experiences, status, error, query } = action.payload;
            const key = `${propertyId}#${experienceTab}`;
            const newExperiences = getNewList(query, state[key].experiences, experiences);
            const indexes = getListIndexes(newExperiences);
            return {
                ...state,
                [key]: {
                    experiences: newExperiences,
                    status,
                    error,
                    indexes,
                    noResults: !newExperiences.length,
                },
            };
        }
        case types.REFRESH_EXPERIENCES: {
            const { propertyId, changes } = action.payload;
            const newState = {};
            const experienceTabs = ['draft', 'template', 'shared'];

            experienceTabs.forEach((experienceTab) => {
                const key = `${propertyId}#${experienceTab}`;
                const newExperiences = handleListRefresh(
                    (state[key] || {}).experiences,
                    (state[key] || {}).indexes,
                    changes,
                );
                newState[key] = {
                    ...(state[key] || {}),
                    experiences: newExperiences,
                };
            });

            return { ...state, ...newState };
        }
        case types.SET_EXPERIENCES_REQUEST_STATUS: {
            const { propertyId, experienceTab, status } = action.payload;
            const key = `${propertyId}#${experienceTab}`;
            return { ...state, [key]: { ...(state[key] || {}), status } };
        }
        case types.RESET_EXPERIENCES: {
            const { propertyId, experienceTab } = action.payload;
            if (propertyId && experienceTab) {
                const key = `${propertyId}#${experienceTab}`;
                return { ...state, [key]: initialState };
            }

            return {};
        }
        default:
            return state;
    }
};
