import { TAB_STATUSES } from '../../../constants/tabStatuses';
import { currentPresentationSelector } from '../currentPresentation/selectors';
import { currentVisualizationSelector } from '../currentVisualization/selectors';
import { visualBuilderValuesSelector } from '../visualBuilderValues/selectors';

export const visualBuilderDataSelector = (params) => (state) => {
    const { propertyId, experienceId, userId, tabStatus } = params;

    if ([TAB_STATUSES.HOMEBASE, TAB_STATUSES.COLLECT].includes(tabStatus)) {
        const currentPresentation = currentPresentationSelector(propertyId)(state);
        const { error, presentation, status } = currentPresentation;
        return { visualBuilderData: presentation, error, status };
    }

    if (userId && [TAB_STATUSES.CUSTOMIZE, TAB_STATUSES.SHARE].includes(tabStatus)) {
        const currentVisualization = currentVisualizationSelector(propertyId, experienceId, userId)(state);
        const { error, visualization, status } = currentVisualization;
        return { visualBuilderData: visualization, error, status };
    }

    if (TAB_STATUSES.PUBLIC) {
        const visualBuilderValues = visualBuilderValuesSelector(propertyId)(state) || {};
        const visualizationId = visualBuilderValues[experienceId];
        const currentVisualization = currentVisualizationSelector(propertyId, experienceId, visualizationId)(state);
        const { error, visualization, status } = currentVisualization;
        return { visualBuilderData: visualization, error, status };
    }

    return {};
};
