import { getListIndexes, getNewList, handleListRefresh } from '../../../utils/helper';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses';
import * as types from './types';

const initialState = {
    mediaItems: [],
    indexes: {},
    status: REQUEST_STATUSES.IDLE,
    error: null,
    noResults: false,
};

export const mediaItemsReducer = (state = {}, action) => {
    switch (action.type) {
        case types.GET_MEDIA_ITEMS: {
            const { propertyId, mediaItems, status, error, query } = action.payload;
            const newMediaItems = getNewList(query, state[propertyId].mediaItems, mediaItems);
            const indexes = getListIndexes(newMediaItems);
            return {
                ...state,
                [propertyId]: {
                    mediaItems: newMediaItems,
                    status,
                    error,
                    indexes,
                    noResults: !newMediaItems.length,
                },
            };
        }
        case types.REFRESH_MEDIA_ITEMS: {
            const { propertyId, changes } = action.payload;
            const newMediaItems = handleListRefresh(
                (state[propertyId] || {}).mediaItems,
                (state[propertyId] || {}).indexes,
                changes,
            );

            return {
                ...state,
                [propertyId]: {
                    ...(state[propertyId] || {}),
                    mediaItems: newMediaItems,
                },
            };
        }
        case types.SET_MEDIA_ITEMS_REQUEST_STATUS: {
            const { propertyId, status } = action.payload;
            return {
                ...state,
                [propertyId]: { ...(state[propertyId] || {}), status },
            };
        }
        case types.RESET_MEDIA_ITEMS: {
            const { propertyId } = action.payload;
            if (propertyId) {
                return { ...state, [propertyId]: initialState };
            }

            return {};
        }
        default:
            return state;
    }
};
