import React from 'react';
import { FirekitContext } from './Context';

const Provider = (props) => {
    const { firebaseApp, context } = props;

    return <FirekitContext.Provider value={{ firebaseApp, context }}>{props.children}</FirekitContext.Provider>;
};

export default Provider;
