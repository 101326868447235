import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const UserEmpty = (props) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.64453 17.2358V14.5988C4.64453 12.8618 5.85053 11.3858 7.53353 10.9448C6.85853 10.3238 6.47153 9.47778 6.47153 8.54178C6.47153 6.68778 8.04653 5.17578 9.99053 5.17578C10.9715 5.17578 11.8445 5.55378 12.4745 6.16578C13.1225 6.76878 13.5095 7.62378 13.5095 8.54178C13.5095 9.46878 13.1315 10.3238 12.4655 10.9448C13.1945 11.1428 13.8335 11.5388 14.3285 12.0518C14.9585 12.7268 15.3455 13.6268 15.3455 14.5988V17.2358"
                stroke={props.htmlColor || '#333333'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.3545 17.2344C13.8605 18.3414 12.0065 18.9984 9.99953 18.9984C7.99253 18.9984 6.14753 18.3414 4.64453 17.2344"
                stroke={props.htmlColor || '#333333'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.645 17.236C2.431 15.598 1 12.97 1 10C1 5.032 5.032 1 10 1C14.968 1 19 5.032 19 10C19 12.97 17.569 15.598 15.355 17.236"
                stroke={props.htmlColor || '#333333'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};

export default UserEmpty;
